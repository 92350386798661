import {usePaymentStatusStore_toBeRemoved} from '@/app/shared/state/PaymentStatusModule';
import {Checkout} from '@/app/checkout/checkout-routes';
import {useUserStore} from '@/app/shared/state/UserDataModule';
import DateUtil from '@/app/shared/helpers/date-util';
import {PaymentType, usePaymentStatusStore} from "@/stores/payment-status-store";
import {computed, defineComponent, onMounted, ref} from 'vue';

export default defineComponent({
    name: 'KlPaymentMessages',
    props: {},
    setup(props) {

        const onlinePaymentMessage = ref<any>(null);
        const hasPrepaidPaymentMessage = ref<boolean>(false);

        const routeCheckout = computed(() => {
            return Checkout;
        });

        const numberOfItemsInCart = computed((): number => {
            return usePaymentStatusStore_toBeRemoved().numberOfItemsInCart;
        });

        const isShoppingCartPaymentVisible = computed((): boolean => {
            return usePaymentStatusStore_toBeRemoved().isShoppingCartPaymentVisible && !hasPrepaidPaymentMessage.value && !hasPrepaidPaymentError.value;
        });

        const hasPaymentError = computed((): boolean => {
            return usePaymentStatusStore_toBeRemoved().hasPaymentError || usePaymentStatusStore().onlinePaymentError;;
        });

        const hasPrepaidPaymentError = computed((): boolean => {
            return (usePaymentStatusStore_toBeRemoved().hasPrepaidPaymentError && !usePaymentStatusStore_toBeRemoved().hasUncertainPrepaidPaymentError) || usePaymentStatusStore().topUpPaymentError;
        });

        const hasUncertainPrepaidPaymentError = computed((): boolean => {
            return usePaymentStatusStore_toBeRemoved().hasUncertainPrepaidPaymentError;
        });


        onMounted(() => {
            if (usePaymentStatusStore_toBeRemoved().hasOnlinePaymentData || usePaymentStatusStore().isOnlinePayment) {
                _setOnlinePaymentMessage();
            } else if (usePaymentStatusStore_toBeRemoved().hasPrepaidPaymentMessageData || usePaymentStatusStore().isTopUpPayment) {
                hasPrepaidPaymentMessage.value = true;
            }
        });

        const _setOnlinePaymentMessage = () => {
            const workingDayAndHolidays = DateUtil.getWorkingDayByCountAndHolidays(DateUtil.now(), 15);
            const onlinePaymentData = usePaymentStatusStore_toBeRemoved().onlinePaymentData ?? usePaymentStatusStore().onlinePayment;
            const holidays = workingDayAndHolidays.holidays && workingDayAndHolidays.holidays.length > 0 ? _getHolidays(workingDayAndHolidays.holidays) : null;

            const title: string = onlinePaymentData.paymentType === 'shoppingcart' || onlinePaymentData.paymentType === PaymentType.Online
                ? 'Betaling goed ontvangen!'
                : (onlinePaymentData.numberOfMapRequests === 0
                    ? 'Betaling goed ontvangen!'
                    : (onlinePaymentData.numberOfMapRequests === 1
                        ? 'Planaanvraag verstuurd!'
                        : 'Planaanvragen verstuurd!'));

            const availableDate = workingDayAndHolidays
                ? workingDayAndHolidays.workingDay
                : DateUtil.now().add(15, 'day')

            onlinePaymentMessage.value = {
                title,
                hasMapRequests: onlinePaymentData.numberOfMapRequests > 0,
                mapRequestSubject: onlinePaymentData.numberOfMapRequests > 1 ? 'Uw planaanvragen zijn' : 'Uw planaanvraag is',
                availableDate: DateUtil.formatDate(availableDate),
                hasMaprequestsWithImkl: onlinePaymentData.hasMapRequestsWithImkl,
                hasMapRequestExtras: onlinePaymentData.hasMapRequestExtras,
                totalPrice: useUserStore().applyVat ? (onlinePaymentData.totalPrice * (1+(useUserStore().vatPercentage/100))) : onlinePaymentData.totalPrice,
                showHolidays: workingDayAndHolidays.holidays && workingDayAndHolidays.holidays.length > 0,
                holidays,
            };
        }

        const _getHolidays = (holidaysList: string[]): string => {
            let holidaysText = '';

            for (let i = 0; i < holidaysList.length; i++) {
                holidaysText = holidaysText.concat(holidaysList[i]);
                if (i < holidaysList.length - 2) {
                    holidaysText = holidaysText.concat(', ');
                } else if (i === holidaysList.length - 2) {
                    holidaysText = holidaysText.concat(' en ');
                }
            }

            return holidaysText;
        }

        const closePaymentErrorMessage = () => {
            usePaymentStatusStore().hasOnlinePaymentError(false);
            return usePaymentStatusStore_toBeRemoved().setPaymentError(false);
        }

        const closePrepaidPaymentErrorMessage = () => {
            usePaymentStatusStore().hasTopUpPaymentError(false);
            return usePaymentStatusStore_toBeRemoved().setPrepaidPaymentError(false);
        }

        const closeUncertainPrepaidPaymentErrorMessage = () => {
            usePaymentStatusStore().hasUncertainPrepaidError(false);
            return usePaymentStatusStore_toBeRemoved().setHasUncertainPrepaidError(false);
        }

        const closeOnlinePaymentMessage = () => {
            onlinePaymentMessage.value = null;
            usePaymentStatusStore().setOnlinePayment(null);
            return usePaymentStatusStore_toBeRemoved().clearOnlinePayment();
        }

        const closePrepaidPaymentMessage = () => {
            hasPrepaidPaymentMessage.value = false;
            usePaymentStatusStore().setOnlinePayment(null);
            return usePaymentStatusStore_toBeRemoved().clearPrepaidPaymentMessage();
        }

        return {
            onlinePaymentMessage,
            hasPrepaidPaymentMessage,

            routeCheckout,
            numberOfItemsInCart,
            isShoppingCartPaymentVisible,
            hasPaymentError,
            hasPrepaidPaymentError,
            hasUncertainPrepaidPaymentError,

            closePaymentErrorMessage,
            closePrepaidPaymentErrorMessage,
            closeUncertainPrepaidPaymentErrorMessage,
            closeOnlinePaymentMessage,
            closePrepaidPaymentMessage,
        }
    }
})
