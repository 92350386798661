<div id="admin-settings-holidays">
    <vl-region v-if="viewModelLoading">
        <vl-layout>
            <div v-vl-align:center>
                <vl-loader message="De gegevens worden geladen. Even geduld, a.u.b."/>
            </div>
        </vl-layout>
    </vl-region>

    <template v-else>
        <vl-region>
            <vl-form-grid mod-stacked>
                <vl-column>
                    <vl-title tag-name="h2">Feestdagen</vl-title>
                </vl-column>
                <vl-column>
                    <template v-for="(holidaysInYear, year) in holidays">
                        <vl-title tag-name="h3">{{year}}</vl-title>
                        <vl-u-spacer mod-small/>
                        <span class="holiday" v-for="(holiday, index) in holidaysInYear">
                            {{formatHoliday(holiday)}}<template v-if="index < holidaysInYear.length - 1">,</template>
                        </span>
                        <vl-u-spacer mod-medium />
                    </template>
                </vl-column>
            </vl-form-grid>
        </vl-region>
    </template>
</div>
