import { defineComponent, inject, Ref, ref, watch } from 'vue'
import { WMTSCapabilities } from 'ol/format'
import proj4 from 'proj4'
import { register } from 'ol/proj/proj4'
import { WMTS } from 'ol/source'
import { optionsFromCapabilities } from 'ol/source/WMTS'
import { Projection } from 'ol/proj'

proj4.defs(
  'EPSG:31370',
  '+proj=lcc +lat_1=51.16666723333333 +lat_2=49.8333339 +lat_0=90 +lon_0=4.367486666666666 ' +
    '+x_0=150000.013 +y_0=5400088.438 +ellps=intl +towgs84=-106.869,52.2978,-103.724,0.3366,-0.457,1.8422,-1.2747 +units=m +no_defs'
)
register(proj4)

export default defineComponent({
  name: 'VlOlMapTileWmtsSource',
  props: {
    url: {
      type: String,
      required: true
    },
    layers: {
      type: Array,
      default: null
    }
  },
  setup(props) {
    const source: Ref = inject('source')
    const options = ref(null)
    async function getCapabilities() {
      if (WMTSCapabilities) {
        const response = await fetch(`${props.url}?request=getcapabilities`)
        return new WMTSCapabilities().read(await response.text())
      }
      return undefined
    }

    watch(
      () => options.value,
      (options) => {
        source.value = new WMTS(options)
      },
      { deep: true }
    )
    watch(
      () => props.url,
      async () => {
        options.value = optionsFromCapabilities(await getCapabilities(), {
          layer: props.layers
        })
      },
      { immediate: true }
    )
  }
})
