import { Ref, ref, watch } from 'vue'
import { Map } from 'ol'
import { Layer } from 'ol/layer'
import VectorLayer from 'ol/layer/Vector'

export function VlOlMapLayer(mapRef: Ref<Map>) {
  const layer = ref(null)
  const source = ref(null)
  const style = ref(null)
  const map = mapRef

  watch(
    () => layer.value,
    (newLayer, existingLayer) => {
      if (newLayer && !existingLayer) {
        if (newLayer instanceof VectorLayer) {
          layer.value.setStyle(style.value)
        }
        map.value?.addLayer(newLayer)
      }
    }
  )

  watch(
    () => source.value,
    () => {
      if (layer.value) {
        ;(layer.value as Layer).setSource(source.value)
        layer.value.getSource().refresh()
      }
    }
  )

  return {
    layer,
    source,
    style
  }
}
