import Collection from 'ol/Collection';
import Feature from 'ol/Feature';
import {Vector as SourceVector} from 'ol/source';
import {computed, onUnmounted, ref, Ref, shallowRef, triggerRef} from 'vue';
import {EDrawZoneMapFeaturePropertyKey} from '@/app/shared/components/kl-draw-zone-map/kl-draw-zone-map-utils';
import BaseEvent from 'ol/events/Event';

export interface IDrawZoneContext {
    currentFeatureRef;
    source;
    selectedFeatures;

    addFeature;
    removeFeature;
    findFeature;
    setCurrentFeature;
    getCurrentFeature;
    selectFeature;
    revertFeatureChanges;
    clear;
}

export function useDrawZoneContext(): IDrawZoneContext {

    const _currentFeature: Ref<Feature> = shallowRef<Feature>(null);

    // used for 'watch'
    const currentFeatureRef = computed(() => {
        return _currentFeature;
    });

    let _featureBackup: Feature = null;
    const _mapFeatures: Collection<Feature> = new Collection();

    const source = new SourceVector({ features: _mapFeatures });
    const selectedFeatures: Collection<Feature> = new Collection();

    const addFeature = (feature: Feature) => {
        _mapFeatures.push(feature);
    }

    const removeFeature = (feature: Feature) => {
        _mapFeatures.remove(feature);
        selectedFeatures.remove(feature);
    }

    const findFeature = (zoneId: number): Feature => {
        let feature: Feature = null;
        _mapFeatures.forEach((f: Feature) => {
            if (f.get(EDrawZoneMapFeaturePropertyKey.drawZoneId) === zoneId) {
                feature = f;
            }
        });
        return feature;
    }

    const _onCurrentFeatureChange = (event: BaseEvent) => {
        triggerRef(_currentFeature);
    }

    const setCurrentFeature = (feature: Feature) => {
        if (_currentFeature.value) {
            _currentFeature.value.un('change', _onCurrentFeatureChange);
        }

        _currentFeature.value = feature;

        if (_currentFeature.value) {
            _currentFeature.value.on('change', _onCurrentFeatureChange);
        }
    }
    const getCurrentFeature = (): Feature => {
        return _currentFeature.value;
    }

    const selectFeature = (feature: Feature) => {
        selectedFeatures.clear();
        if (feature) {
            selectedFeatures.push(feature);
            _featureBackup = feature.clone();
        }
    }

    const revertFeatureChanges = () => {
        if ((selectedFeatures.getLength() === 0) || !_featureBackup) {
            return;
        }
        selectedFeatures.item(0).setGeometry(_featureBackup.getGeometry());
    }

    const clear = () => {
        setCurrentFeature(null);

        _featureBackup = null;

        _mapFeatures.clear();
        selectedFeatures.clear();
    }

    onUnmounted(() => {
        setCurrentFeature(null);
    })

    return {
        currentFeatureRef,
        source,
        selectedFeatures,

        addFeature,
        removeFeature,
        findFeature,
        setCurrentFeature,
        getCurrentFeature,
        selectFeature,
        revertFeatureChanges,
        clear,
    }
}
