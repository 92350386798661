import { defineComponent, inject, onBeforeUnmount, onMounted, provide, Ref } from 'vue'
import VectorLayer from 'ol/layer/Vector'
import VectorSource from 'ol/source/Vector'
import { VlOlMapLayer } from '../vl-ol-map-layer/vl-ol-map-layer-composable'
import { Map } from 'ol'

export default defineComponent({
  name: 'VlOlMapVectorLayer',
  setup() {
    const olMap: Ref<Map> = inject('olMap')
    const { layer, source, style } = VlOlMapLayer(olMap)

    onMounted(() => {
      layer.value = new VectorLayer({
        source: new VectorSource()
      })
    })

    onBeforeUnmount(() => {
      olMap.value.removeLayer(layer.value)
    })

    provide('layer', layer)
    provide('source', source)
    provide('style', style)

    return {
      layer
    }
  }
})
