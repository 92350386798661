import {computed, defineComponent, onBeforeUnmount, onMounted, ref, watch} from 'vue';

export default defineComponent({
    name: 'IpproMapFullscreen',
    emits: ['change'],
    props: {
        initActive: {
            type: Boolean,
            default: false,
        },
    },
    setup(props, {emit}) {
        const active = ref<boolean>(false);
        const toggleFullscreen = (newValue: boolean) => active.value = newValue;

        const _fullscreenKeyboardHandler = (event: KeyboardEvent) => {
            if (event.keyCode === 27) {
                active.value = false;
            }
        }

        watch(
            active,
            (newValue) => {
                emit('change', newValue);
            });


        watch(
            () => props.initActive,
            (newValue) => {
                active.value = newValue;
            });

        onMounted(() => {
            window.addEventListener("keyup", _fullscreenKeyboardHandler);
            if (props.initActive) {
                active.value = true;
            }
        });

        onBeforeUnmount(() => {
            window.removeEventListener("keyup", _fullscreenKeyboardHandler);
        });

        return {
            active,
            toggleFullscreen,
        }
    }
})
