import {computed, defineComponent, onMounted, provide, ref} from 'vue'
import {fromLonLat, Projection} from 'ol/proj'
import {Coordinate} from 'ol/coordinate'
import {Map, View} from 'ol'
import {defaults} from 'ol/interaction'
import proj4 from 'proj4'
import {register} from 'ol/proj/proj4'

proj4.defs(
    'EPSG:31370',
    '+proj=lcc +lat_1=51.16666723333333 +lat_2=49.8333339 +lat_0=90 +lon_0=4.367486666666666 +x_0=150000.013 +y_0=5400088.438 +ellps=intl +towgs84=-106.868628,52.297783,-103.723893,0.336570,-0.456955,1.842183,-1.2747 +units=m +no_defs'
)

register(proj4)

export default defineComponent({
    name: 'VlOlMap',
    compatConfig: {
        MODE: 3
    },
    props: {
        tagName: {
            type: String,
            default: 'div'
        },
        center: {
            type: Array,
            default: () => [4.3113025, 51.0238049]
        },
        zoom: {
            type: Number,
            default: 8
        },
        maxZoom: {
            type: Number,
            default: 20
        },
        minZoom: {
            type: Number,
            default: 1
        },
        modBoxed: {
            type: Boolean,
            default: false
        },
        mapDisableMouseWheelZoom: {
            type: Boolean,
            default: false
        },
        projection: {
            type: Projection,
            default: null
        }
    },
    emits: [],
    setup(props, {emit}) {
        const mapRef = ref(null)
        const olMap = ref<Map>(null)

        provide('olMap', olMap)

        const classes = computed(() => {
            return ['vl-ol-map', {'vl-ol-map--boxed': props.modBoxed}]
        })

        onMounted(() => {
            olMap.value = new Map({
                controls: [],
                view: new View({
                    projection: props.projection,
                    center: fromLonLat(props.center as Coordinate),
                    zoom: props.zoom,
                    maxZoom: props.maxZoom,
                    minZoom: props.minZoom
                }),
                target: mapRef.value,
                interactions: defaults({mouseWheelZoom: !props.mapDisableMouseWheelZoom})
            })
        })

        return {
            classes,
            mapRef,
            olMap
        }
    }
})
