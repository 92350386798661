<vl-region>
    <vl-form-grid mod-stacked>
        <vl-column>
            <kl-search-maprequests
                only-active
                @search="search"
                ref="searchMaprequests"
                :default-filter="defaultFilter"
                :search-fields="mapRequestsSearchFields"
                :default-search-option="defaultSearchOption"
                v-if="mapRequestsSearchFields.length"
            />
        </vl-column>

        <vl-column>
            <ippro-datatable
                :meta="mapRequestsMeta"
                :dataset="mapRequests"
                :columns="mapRequestsColumns"
                :fetching="mapRequestsFetching"
                @pager-clicked="pagerClicked"
                @column-clicked="columnClicked"
                mod-clickable-rows
                mod-pagination-on-top
                mod-zebra>
                <template v-slot:noresults>
                    <div v-vl-align:center>
                        <p>Uw zoekopdracht heeft geen resultaten opgeleverd.</p>
                        <vl-u-spacer mod-xsmall/>
                        <vl-button @click="clearSearch" class="vl-button--link">Zoekvelden wissen
                        </vl-button>
                    </div>
                </template>
            </ippro-datatable>
        </vl-column>
    </vl-form-grid>
</vl-region>
