import { first, every } from 'lodash-es';
import {computed, defineComponent, onMounted, ref} from 'vue';
import {IDrawZoneMapGeometry} from '@/app/shared/components/kl-draw-zone-map/kl-draw-zone-map';
import {useKlModalEventsUtil} from '@/app/shared/helpers/kl-modal-events-util';

export interface ILocationPickerGeometry {
    coordinates: number[][][];
}

export default defineComponent({
    name: 'KlLocationpicker',
    emits: ['input'],
    props: {
        value: {
            type: Object as () => ILocationPickerGeometry,
            required: false,
            default: null,
        }
    },
    setup(props,  { emit }) {

        const locationPickerModalEvents = useKlModalEventsUtil('location-picker-modal', {
            onOpened() {
                drawZoneActive.value = true;
                _setZoneFromVModel();
            },
            onClosed() {
                drawZoneActive.value = false;
            },
        });

        const location = ref<ILocationPickerGeometry>(null);
        const zones = ref<ILocationPickerGeometry[]>(null);
        const drawZoneActive = ref<boolean>(false);
        const isSearchValid = ref<boolean>(true);

        const applyButtonDisabled = computed(() => {
            return !isSearchValid.value;
        });


        const onZonesChanged = (newZones: IDrawZoneMapGeometry[]) => {
            zones.value = newZones?.map((current: IDrawZoneMapGeometry) => ({ coordinates: current.coordinates as number[][][] }));
            // validation: no zone is also valid! (not required)
            isSearchValid.value = every(newZones, (cz: IDrawZoneMapGeometry) => cz.isValid);
        }

        const editLocation = () => {
            if (location.value) {
                toggleModal();
            }
        }

        const toggleModal = () => {
            locationPickerModalEvents.triggerModalToggle();
        }

        const removeLocation = () => {
            drawZoneActive.value = false;
            location.value = null;
            zones.value = null;
            emit('input', null);
        }

        const applyZone = () => {
            location.value = first(zones.value);
            emit('input', location.value);
            toggleModal();
        }

        const _setZoneFromVModel = () => {
            if (props.value) {
                location.value = props.value;
                zones.value = [location.value];
            }
        }


        onMounted(() => {
            _setZoneFromVModel();
        });


        return {
            location,
            zones,
            drawZoneActive,
            isSearchValid,
            applyButtonDisabled,
            onZonesChanged,
            editLocation,
            toggleModal,
            removeLocation,
            applyZone,
        }
    }
})
