import {defineComponent} from 'vue';
import dayjs from 'dayjs';
import DateUtil from '@/app/shared/helpers/date-util';

export default defineComponent({
    name: 'KlDatepicker',
    props: {
        modelValue: { type: String, required: true },
        placeholder: { type: String, default: 'dd/mm/jjjj' },
        visualFormat: { type: String, default: 'd/m/Y' },
    },
    emits: ['update:modelValue'],
    compatConfig: {
        MODE: 3
    },
    setup(props, { emit }) {

        const onUpdate = (newValue: string) => {

            // TODO: WebComponents bug opvolgen
            // newValue hoort een Array van ?? te zijn, maar is momenteel de string die in het input veld staat

            // wat kan er hier allemaal uitkomen?
            // refs:
            // - events: https://flatpickr.js.org/events/
            // - setDate: https://flatpickr.js.org/instance-methods-properties-elements/#methods
            //     (FlatPickr.setDate = string | Date | Date[])

            // const date = DateUtil.parseToDate(newValue);
            const date = DateUtil.formatDate(newValue);
            emit('update:modelValue', [date]);
        }

        return {
            onUpdate,
        }
    }
});
