import {computed, defineComponent, onMounted, onUnmounted, provide, ref} from 'vue'

export default defineComponent({
    name: 'VlOlMapBaseLayer',
    // compatConfig: {
    //     MODE: 3
    // },
    inheritAttrs: true,
    props: {
        tagName: {
            type: String,
            default: 'div'
        },
        modToggle: Boolean
    },
    emits: ['change'],
    setup(props, {emit}) {
        const open = ref(false)
        const classes = computed(() => {
            return ['vl-ol-map-base-layer', {'vl-ol-map-base-layer--open': open.value}, {'vl-ol-map-base-layer--toggle': props.modToggle}]
        })

        function toggleBaseLayer() {
            open.value = !open.value
        }

        function closeBaseLayer() {
            open.value = false
        }

        function handleChangeEvent($event: any, active: boolean) {
            closeBaseLayer()
            emit('change', {$event, active})
        }

        provide('handleChangeEvent', {handleChangeEvent})

        return {
            classes,
            toggleBaseLayer
        }
    }
});
