import { computed, defineComponent, inject, Ref, ref, watch } from 'vue'

import TileWMS from 'ol/source/TileWMS'
import WMSCapabilities from 'ol/format/WMSCapabilities'

export default defineComponent({
  name: 'VlOlMapTileWmsSource',
  props: {
    url: {
      type: String,
      required: true
    },
    version: {
      type: String,
      default: null
    },
    service: {
      type: String,
      default: null
    },
    layers: {
      type: Array,
      default: null
    }
  },
  setup(props) {
    const version = ref(null)
    const service = ref(null)
    const layers = ref(null)

    const source: Ref = inject('source')

    const params = computed(() => {
      return {
        version: props.version || version.value,
        service: props.service || service.value,
        layers: props.layers || layers.value
      }
    })

    async function getCapabilities() {
      if (WMSCapabilities) {
        const response = await fetch(`${props.url}?request=getcapabilities`)
        return new WMSCapabilities().read(await response.text())
      }
      return undefined
    }

    watch(
      () => params.value,
      (params) => {
        source.value = new TileWMS({
          url: props.url,
          params,
          serverType: 'geoserver'
        })
      },
      { deep: true, immediate: true }
    )

    watch(
      () => props.url,
      async () => {
        const capabilities = await getCapabilities()
        if (capabilities) {
          version.value = capabilities.version
          service.value = capabilities.Service?.Name
          if (capabilities.Capability?.Layer.Name) {
            layers.value = [capabilities.Capability.Layer.Name]
          } else {
            layers.value = capabilities.Capability?.Layer?.Layer.map((layer) => layer.Name)
          }
        }
      },
      { immediate: true }
    )
  }
})
