import { computed, defineComponent, getCurrentInstance, inject, Ref } from 'vue'

export default defineComponent({
  name: 'VlOlMapBaseLayerToggle',
  compatConfig: {
    MODE: 3
  },
  props: {
    tagName: {
      type: String,
      default: 'label'
    },
    title: {
      type: String,
      default: ''
    },
    image: {
      type: Object,
      default: null
    },
    active: Boolean
  },
  emits: ['_change'],
  setup() {
    const change: { handleChangeEvent: (event: any, value: string) => void } = inject('handleChangeEvent')
    const classes = computed(() => {
      return ['vl-ol-map-base-layer-toggle']
    })

    const emitSelect = ($event: any) => {
      change.handleChangeEvent($event, $event.target.value)
    }

    return {
      classes,
      emitSelect
    }
  }
})
