import VlOlMap from './vl-ol-map.vue';
import VlOlMapToolsBar from './subcomponents/vl-ol-map-tools-bar/vl-ol-map-tools-bar.vue';
import VlOlMapBaseLayer from './subcomponents/vl-ol-map-base-layer/vl-ol-map-base-layer.vue';
import VlOlMapIconStyle from './subcomponents/vl-ol-map-icon-style/vl-ol-map-icon-style.vue';
import VlOlMapInfo from './subcomponents/vl-ol-map-info/vl-ol-map-info.vue';
import VlOlMapInfoItem from './subcomponents/vl-ol-map-info/subcomponents/vl-ol-map-info-item/vl-ol-map-info-item.vue';
import VlOlMapLoader from './subcomponents/vl-ol-map-loader/vl-ol-map-loader.vue';
import VlOlMapOsmSource from './subcomponents/vl-ol-map-osm-source/vl-ol-map-osm-source.vue';
import VlOlMapSearch from './subcomponents/vl-ol-map-search/vl-ol-map-search.vue';
import VlOlMapSelectInteraction from './subcomponents/vl-ol-map-select-interaction/vl-ol-map-select-interaction.vue';
import VlOlMapSource from './subcomponents/vl-ol-map-source/vl-ol-map-source.vue';
import VlOlMapTileLayer from './subcomponents/vl-ol-map-tile-layer/vl-ol-map-tile-layer.vue';
import VlOlMapTileWmsSource from './subcomponents/vl-ol-map-tile-wms-source/vl-ol-map-tile-wms-source.vue';
import VlOlMapTileWmtsSource from './subcomponents/vl-ol-map-tile-wmts-source/vl-ol-map-tile-wmts-source.vue';
import VlOlMapToolsAction from './subcomponents/vl-ol-map-tools-action/vl-ol-map-tools-action.vue';
import VlOlMapToolsActionGroup from './subcomponents/vl-ol-map-tools-action-group/vl-ol-map-tools-action-group.vue';
import VlOlMapVectorLayer from './subcomponents/vl-ol-map-vector-layer/vl-ol-map-vector-layer.vue';
import VlOlMapVectorSource from './subcomponents/vl-ol-map-vector-source/vl-ol-map-vector-source.vue';
import VlOlMapBaseLayerToggle from './subcomponents/vl-ol-map-base-layer/subcomponents/vl-ol-map-base-layer-toggle/vl-ol-map-base-layer-toggle.vue';
import type { App, Plugin } from 'vue';

export default {
    install(app: App) {
        app.component('VlOlMap', VlOlMap);
        app.component('VlOlMapToolsBar', VlOlMapToolsBar);
        app.component('VlOlMapBaseLayer', VlOlMapBaseLayer);
        app.component('VlOlMapBaseLayerToggle', VlOlMapBaseLayerToggle);
        app.component('VlOlMapIconStyle', VlOlMapIconStyle);
        app.component('VlOlMapInfo', VlOlMapInfo);
        app.component('VlOlMapInfoItem', VlOlMapInfoItem);
        app.component('VlOlMapLoader', VlOlMapLoader);
        app.component('VlOlMapOsmSource', VlOlMapOsmSource);
        app.component('VlOlMapSearch', VlOlMapSearch);
        app.component('VlOlMapSelectInteraction', VlOlMapSelectInteraction);
        app.component('VlOlMapSource', VlOlMapSource);
        app.component('VlOlMapTileLayer', VlOlMapTileLayer);
        app.component('VlOlMapTileWmsSource', VlOlMapTileWmsSource);
        app.component('VlOlMapTileWmtsSource', VlOlMapTileWmtsSource);
        app.component('VlOlMapToolsAction', VlOlMapToolsAction);
        app.component('VlOlMapToolsActionGroup', VlOlMapToolsActionGroup);
        app.component('VlOlMapVectorLayer', VlOlMapVectorLayer);
        app.component('VlOlMapVectorSource', VlOlMapVectorSource);
    }
} as Plugin;

export { VlOlMap }
