import {computed, defineComponent} from 'vue';
import {useMetaStore, IBreadCrumb} from '@/stores/meta-store';
import {map} from 'lodash-es';

export default defineComponent({
    name: 'KlBreadcrumb',
    props: {},
    setup(props) {

        const breadcrumbs = computed(() => {
            const metaBreadcrumbs = useMetaStore().breadcrumbs;
            const localBreadcrumbs = map(metaBreadcrumbs, (bc: IBreadCrumb, index) => {
                return {
                    ...bc,
                    disabled: index === metaBreadcrumbs.length - 1 || !routeName(bc),
                }
            });
            return localBreadcrumbs;
        });

        const to = (breadcrumb: IBreadCrumb) => {
            return routeName(breadcrumb) ? {
                name: routeName(breadcrumb),
                hash: breadcrumb.route && breadcrumb.route.hash ? breadcrumb.route.hash : '',
            } : null;
        }

        const routeName = (breadcrumb: IBreadCrumb) => {
            return (breadcrumb.route ? breadcrumb.route.name : '');
        }


        return {
            breadcrumbs,
            to,
            routeName,
        }
    }
})
