import {computed, defineComponent, onMounted, onUnmounted, ref, watch} from "vue";
import * as KlipApi from "@/api/klip-api.proxy";
import {
    CurrentInvoiceRound,
    InvoiceStates,
    IStartInvoicingRoundRequest,
    LogTypes,
    MapRequestWithFinancialAnomalies
} from "@/api/klip-api.proxy";
import { ISelectedOrganisation } from "@/app/admin/components/kl-search-organisation/kl-search-organisation";
import debounce from "lodash-es/debounce";
import Guid from "@/app/shared/helpers/guid";
import {AdminMapRequestWithFinancialAnomalies, AdminSettings} from "@/app/admin/admin-routes";
import {useField, useForm} from 'vee-validate';
import {RouteRecordRaw} from 'vue-router';
import {useKlipApiProxy} from '@/plugins/proxy-client';
import DateUtil from '@/app/shared/helpers/date-util';
import {first} from 'lodash-es';

export default defineComponent({
    setup() {
        const klipApiProxy = useKlipApiProxy();
        const today = DateUtil.formatDate(DateUtil.now());

        const form = useForm();

        const next11thDay = ref<string>('');
        const invoiceFormVisible = ref<boolean>(false);

        const dateFromField = useField<string[]>('Alle aanvragen tot en met', 'required', { initialValue: [today] });
        // const dateFromField = useField<string[]>('Alle aanvragen tot en met', 'required');
        const dateFrom = dateFromField.value;

        const disableSubmit = computed((): boolean => {
            return !form.meta.value.valid;
        })

        let invoiceRoundintervalId: NodeJS.Timer = null;
        const roundSuccessfullyDone = ref<boolean>(false);
        const startingNewRound = ref<boolean>(false);
        const overruleDryRun = ref<boolean>(false);
        const isSending = ref<boolean>(false);
        const organisationDataFetching = ref<boolean>(false);
        const selectedOrganisationTpe = ref<string>('all');
        const dateFromMaxDate = ref<string>(today);
        const selectedOrganisations = ref<ISelectedOrganisation[]>([]);
        const organisationSuggestion = ref<ISelectedOrganisation[]>([]);
        const currentInvoiceRound = ref<KlipApi.CurrentInvoiceRound>(null);
        const currentInvoiceRoundId = ref<string>('');
        const loading = ref<boolean>(true);
        const error = ref<string>('');
        const nothingToInvoice = ref<boolean>(false);
        const mapRequestsNotRegisteredInFinancialTransaction = ref<MapRequestWithFinancialAnomalies[]>([]);
        const organisationSelection = computed((): boolean => {
            return selectedOrganisationTpe.value === 'select';
        });

        const routeAdminMapRequestWithFinancialAnomalies = computed((): RouteRecordRaw => {
            return AdminMapRequestWithFinancialAnomalies;
        });

        const debouncedOrganisationSearch = debounce((searchValue) => {
            if (!searchValue) {
                return;
            }

            organisationDataFetching.value = true;
            klipApiProxy.organisation_FilterOrganisationsByName(searchValue, false, true)
                .then((filterResult) => {
                    organisationSuggestion.value = filterResult.result.map((item) => {
                        return {
                            organisationId: item.organisationId,
                            name: item.name,
                            parentId: item.parentId,
                            namePath: item.namePath
                        }

                    });
                })
                .finally(() => {
                    organisationDataFetching.value = false;
                });
        }, 200);

        const onOrganisationInputChange = (searchQuery: string, id) => {
            debouncedOrganisationSearch(searchQuery);
        };

        const title = computed(() => {
            if (noActiveRoundRunning.value) {
                return 'Facturatieronde';
            } else {
                return `Facturatieronde van ${formatDate(currentInvoiceRound.value.startTime)} - ${currentInvoiceRoundId.value}`
            }
        });

        const pollInvoiceRound = async () => {
            const response = await klipApiProxy.invoice_CurrentInvoiceRound();
            if (response.isSuccessStatusCode && response.result !== null) {
                if (response.result.state === InvoiceStates.InvoicesSent ||
                    response.result.state === InvoiceStates.Completed) {
                    roundSuccessfullyDone.value = true;
                }
                currentInvoiceRound.value = response.result;
            }
        };

        const noActiveRoundRunning = computed(() => {
            return (currentInvoiceRound.value === null || currentInvoiceRound.value === undefined) ||
                currentInvoiceRound.value.state === InvoiceStates.InvoicesSent || currentInvoiceRound.value.state === InvoiceStates.Completed ||
                startingNewRound.value;
        });

        const uniqueFieldId = (id: string) => {
            return id + Guid.randomGuid();
        };

        const formatDate = (timestamp: string) => {
            return DateUtil.formatDateTime(timestamp);
        };

        const _onFormSubmit = async () => {
            startingNewRound.value = true;
            roundSuccessfullyDone.value = false;
            invoiceFormVisible.value = false;

            error.value = '';

            const invoiceRoundData: KlipApi.IStartInvoicingRoundRequest = {
                startDate: DateUtil.formatForApi(DateUtil.parseToDayJs(dateFrom.value[0]).add(1, 'day'), 'ISO'),
                overruleFailedDryRun: overruleDryRun.value,
                organisations: selectedOrganisations.value.map(s => s.organisationId)
            };

            const startInvoiceRoundResult = await klipApiProxy.invoice_StartInvoiceRound(KlipApi.StartInvoicingRoundRequest.fromJS(invoiceRoundData));

            if (startInvoiceRoundResult.result && startInvoiceRoundResult.result.success) {
                if (!!startInvoiceRoundResult.result.invoiceRoundId) {
                    currentInvoiceRoundId.value = startInvoiceRoundResult.result.invoiceRoundId;
                    currentInvoiceRound.value = new CurrentInvoiceRound();
                    startingNewRound.value = false;
                    invoiceRoundintervalId = setInterval(pollInvoiceRound, 5000);
                } else {
                    nothingToInvoice.value = true;
                }
            } else {
                error.value = startInvoiceRoundResult.result.failure.errors[0].message;
            }

            startingNewRound.value = false;
        };

        const logClass = (log: KlipApi.InvoicingLogMessage) => {
            return [
                'kl-invoice-alert', {
                    'kl-invoice-alert--error': log.logTypes === LogTypes.Error,
                    'kl-invoice-alert--warning': log.logTypes === LogTypes.Warning,
                    'kl-invoice-alert--success': log.logTypes === LogTypes.Info,
                }];
        }

        const onDateFromChanged = async (d: string[]) => {
            const from = DateUtil.formatForApi(first(d), 'YYYY/MM/DD');
            const result = await klipApiProxy.invoice_MapRequestsNotRegisteredInFinancialTransaction(from);
            mapRequestsNotRegisteredInFinancialTransaction.value = result.result;
        };

        const calculateNext11thDay = () => {
            const today = DateUtil.now();
            const nextMonth = today.add(1, "month"); // Add one month
            const next11th = nextMonth.date(11); // Set to the 11th day of that month
            next11thDay.value = next11th.format("DD/MM/YYYY");
        };

        const showInvoiceForm = () => {
            invoiceFormVisible.value = true;
        }

        onMounted(async () => {
            calculateNext11thDay();

            const currentRoundResult = await klipApiProxy.invoice_CurrentInvoiceRound();

            if (currentRoundResult.isSuccessStatusCode) {
                currentInvoiceRound.value = currentRoundResult.result;

                // support old and new flow
                if (currentInvoiceRound.value.state !== InvoiceStates.InvoicesSent &&
                    currentInvoiceRound.value.state !== InvoiceStates.Completed) {
                    invoiceRoundintervalId = setInterval(pollInvoiceRound, 5000);
                } else {
                    roundSuccessfullyDone.value = true;
                }
            }

            loading.value = false;
        });

        onUnmounted(() => {
            clearInterval(invoiceRoundintervalId);
        });

        watch(dateFrom, onDateFromChanged, { immediate: true });

        const _onInvalidSubmit = (submittedValues) => {
            console.log('_onInvalidSubmit', submittedValues);
        }

        const onSubmit = form.handleSubmit(_onFormSubmit, _onInvalidSubmit);

        return {
            dateFromField,
            dateFrom,

            isSending,
            formatDate,
            dateFromMaxDate,
            organisationSuggestion,
            selectedOrganisationTpe,
            selectedOrganisations,
            organisationSelection,
            organisationDataFetching,
            currentInvoiceRound,
            currentInvoiceRoundId,
            noActiveRoundRunning,
            roundSuccessfullyDone,
            startingNewRound,
            overruleDryRun,
            title,
            loading,
            mapRequestsNotRegisteredInFinancialTransaction,
            routeAdminMapRequestWithFinancialAnomalies,
            logClass,
            uniqueFieldId,
            onOrganisationInputChange,
            onSubmit,
            disableSubmit,
            nothingToInvoice,
            error,
            showInvoiceForm,
            invoiceFormVisible,
            next11thDay
        };
    }
});
