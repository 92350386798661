<template>
    <component :is="tagName" :class="classes">
        <div class="vl-ol-map__tile-navigation">
            <slot name="tile-navigation"/>
        </div>
        <div class="vl-ol-map__body">
            <slot name="sidebar" />
            <div class="vl-ol-map__content">
                <div ref="mapRef" class="vl-ol-map__map"></div>
                <div class="vl-ol-map__search">
                    <slot name="search" />
                </div>
                <div class="vl-ol-map__action-tools">
                    <slot name="action-tools" />
                </div>
                <div class="vl-ol-map__view-tools">
                    <slot name="view-tools" />
                </div>
                <div class="vl-ol-map__info">
                    <slot name="info" />
                </div>
                <div class="vl-ol-map__base-layer">
                    <slot name="base-layer" />
                </div>
            </div>
        </div>
        <slot />
    </component>
</template>
<script lang="ts" src="./vl-ol-map.ts" />
<style lang="scss" src="./scss/_ol-map.scss" />
