<template>
    <div class="sidebar-panel">
        <kl-draw-zone-sidebar-header :title="title" tag-style="h5" />
        <vl-sidebar-content class="kl-sidebar__section kl-sidebar__section-smaller">
            <vl-title tag-name="h3" tag-style="h6" class="vl-sidebar-header__title kl-sidebar__section__fillrow">
                Teken zone
            </vl-title>
            <vl-button mod-small mod-tertiary @click="removeGeometry">Annuleren</vl-button>
        </vl-sidebar-content>
        <vl-sidebar-content class="kl-sidebar__section kl-sidebar__section-larger" v-if="!isEdit">
            Teken uw zone op de kaart.
            <br/>
            Sluit de zone door te dubbelklikken.
        </vl-sidebar-content>
        <vl-sidebar-content class="kl-sidebar__section kl-sidebar__section-larger" v-if="isEdit">
            Bewerk uw zone op de kaart.
        </vl-sidebar-content>
        <kl-draw-zone-sidebar-filler />
    </div>
</template>

<script lang="ts">

import {computed, defineComponent, ref} from 'vue';
import KlDrawZoneSidebarHeader
    from '@/app/shared/components/kl-draw-zone-map/components/kl-draw-zone-sidebar-header.vue';
import KlDrawZoneSidebarFiller
    from '@/app/shared/components/kl-draw-zone-map/components/kl-draw-zone-sidebar-filler.vue';

export default defineComponent({
    name: 'KlDrawZoneSidebarGeometryCreateEdit',
    emits: ['remove-geometry'],
    components: {
        KlDrawZoneSidebarFiller,
        KlDrawZoneSidebarHeader
    },
    props: {
        title: { type: String, required: true },
        isEdit: { type: Boolean, default: false },
    },
    setup(props, { emit }) {

        const removeGeometry = () => {
            emit('remove-geometry');
        }

        return {
            removeGeometry,
        }
    }
})

</script>
