import {defineStore} from "pinia";
import {inject, ref} from "vue";
import {IZoneToMove} from "@/app/admin/organisation/components/kl-una-zone-move/kl-una-zone-move";
import * as KlipApi from "@/api/klip-api.proxy";
import {OrganisationDetail} from "@/api/klip-api.proxy";

export const useUnaZoneStore = defineStore('kl-una-zone-store', () => {
    const moveZoneMessage = ref<string>('');
    const movedZone = ref<IZoneToMove>(null);

    const setMoveZoneMessage = (moveZone: IZoneToMove, zoneName: string, sourceOrganisationName: string) => {
        movedZone.value = moveZone;
        moveZoneMessage.value = sourceOrganisationName ?
            `De zone '${zoneName}' wordt verplaatst van ${sourceOrganisationName} naar ${moveZone.destinationKlipOrganisationName}` :
            `De zone '${zoneName}' wordt verplaatst naar ${moveZone.destinationKlipOrganisationName}`;
    };

    const hasMovedZoneInOrganisation = (organisationId: OrganisationDetail) => {
        return movedZone.value && organisationId.organisationsDataTable.some(org => org.id === movedZone.value.sourceKlipOrganisationId);
    };

    const clearMoveZoneMessage = () => {
        moveZoneMessage.value = '';
        movedZone.value = null;
    }

    return {
        moveZoneMessage,
        hasMovedZoneInOrganisation,
        setMoveZoneMessage,
        clearMoveZoneMessage
    }
});