import { computed, defineComponent, inject, ref, watch } from "vue";
import * as KlipApi from "@/api/klip-api.proxy";
import { isEmpty } from "lodash-es";
import DateUtil from '@/app/shared/helpers/date-util';
export default defineComponent({
    props: {
        user: {
            type: KlipApi.UserDetail,
            default: null,
            required: true
        }
    },
    setup(props) {
        const rolesInOrganisation = ref<string>('');
        const lastActiveInOrganisation = ref<string>('');
        const userDetail = ref<KlipApi.UserDetail>(null);

        const userChanged = (user: KlipApi.UserDetail) => {
            userDetail.value = user;
            if (user.lastActiveAt) {
                lastActiveInOrganisation.value = DateUtil.formatDateTime(user.lastActiveAt);
            }
            if (!isEmpty(user.rolesInOrganisation)) {
                rolesInOrganisation.value = user.rolesInOrganisation.join(', ');
            }
        };

        const hasMriRole = computed(() => {
            if (userDetail.value.isCitizen) {
                return true;
            }
            if (isEmpty(userDetail.value.rolesInOrganisation)) {
                return false;
            }
            return userDetail.value.rolesInOrganisation.includes('KLIP Planaanvrager');
        });

        watch(props.user, userChanged, { immediate: true, deep: true });


        return {
            lastActiveInOrganisation,
            rolesInOrganisation,
            hasMriRole,
            userDetail,
        }
    }
});
