<template>
    <div :class="classes">
        <vl-ol-map
            v-bind="$attrs"
            :zoom="zoom"
            :center="center"
            :ref="refMap"
            :id="olMapId"
            tabindex="0"
            :style="mapStyle"
            mod-boxed
            :map-disable-mouse-wheel-zoom="modDisableScrollZoom"
        >
            <template v-slot:default>
                <vl-ol-map-loader v-if="isLoading" mod-blocking>
                    {{ loadingMessage }}
                </vl-ol-map-loader>
                <slot></slot>
            </template>
            <template v-slot:tile-navigation>
                <vl-tile-navigation v-if="!!$slots['tile-navigation']">
                    <slot name="tile-navigation"></slot>
                </vl-tile-navigation>
            </template>
            <template v-slot:sidebar>
                <slot name="sidebar"></slot>
            </template>
            <template v-slot:search>
                <slot name="search"></slot>
            </template>
            <template v-slot:base-layer>
                <ippro-map-layer-toggle
                    ref="layerToggle"
                    :layers="layers"
                    @switch-layer="setLayer"
                />
                <slot name="base-layer"></slot>
            </template>
            <template v-slot:action-tools>
                <slot name="action-tools"></slot>
            </template>
            <template v-slot:view-tools>
                <vl-ol-map-tools-bar mod-vertical v-vl-print:no>
                    <vl-ol-map-tools-action-group mod-vertical v-if="modToggleFullscreen">
                        <ippro-map-fullscreen @change="toggleFullscreen" :init-active="fullscreen"/>
                    </vl-ol-map-tools-action-group>
                    <slot name="view-tools"></slot>
                    <vl-ol-map-tools-action-group mod-vertical v-if="modZoom">
                        <vl-ol-map-tools-action
                            @click.prevent="zoomIn"
                            ref="zoomInButton"
                            icon="plus"
                            text="Zoom in"
                            v-vl-tooltip.left.hide-immediately="'Zoom&nbsp;in'"
                        />
                        <vl-ol-map-tools-action
                            @click.prevent="zoomOut"
                            ref="zoomOutButton"
                            icon="minus"
                            text="Zoom uit"
                            v-vl-tooltip.left.hide-immediately="'Zoom&nbsp;uit'"
                        />
                    </vl-ol-map-tools-action-group>
                </vl-ol-map-tools-bar>
            </template>
            <template v-slot:info>
                <vl-ol-map-info>
                    <vl-ol-map-info-item :id="mapScaleId" label=""/>
                    <vl-ol-map-info-item label="Lambert 72"/>
                    <vl-ol-map-info-item

                        ref="mapMousePosition"
                        :id="mapMousePositionId"
                        label=""
                    />
                    <slot name="map-info"></slot>
                </vl-ol-map-info>
            </template>
        </vl-ol-map>
        <div class="ippro-map__placeholder"></div>
    </div>
</template>

<script lang="ts" src="./vl-ippro-map.ts"></script>

<style lang="scss">
@import "./vl-ippro-map.scss";
</style>
