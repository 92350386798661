import {IpproMapLayer} from "../../vl-ippro-map-layers";
import {defineComponent} from 'vue';

export default defineComponent({
    name: 'IpproMapLayerToggle',
    emits: ['switch-layer'],
    props: {
        layers: {
            type: Array as () => IpproMapLayer[],
            default: () => [],
        },
    },
    setup(props, {emit}) {
        const changeLayer = (layerToggle: { $event: Event; active: string }) => {
            const layer: IpproMapLayer = props.layers.find((obj: IpproMapLayer) => obj.id === layerToggle.active);
            if (layer) {
                emit('switch-layer', layer);
            }
        }

        return {changeLayer}
    }
})
