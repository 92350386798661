<!--<vl-form-validation-observer v-slot="{ handleSubmit, invalid, pristine }">-->
    <form class="vl-form" @submit="onSubmit">
        <vl-column>
            <vl-title tag-name="h2">Kabel- en leidingbeheerder</vl-title>
        </vl-column>
        <vl-column>
            <kl-description-list>
                <kl-description-list-row label="Naam als leidingbeheerder in KLIP:">
                    {{ displayName }}
                </kl-description-list-row>
                <kl-description-list-row label="Subject signing certificaat:">
                    {{ subjectSigningCertificate }}
                </kl-description-list-row>
            </kl-description-list>
        </vl-column>
        <vl-u-spacer mod-small/>
        <vl-column v-if="!zonesFetching">
            <vl-title tag-name="h3">
                Welke meldingen wil de KLB ontvangen via e-mail?
            </vl-title>
            <kl-field-validation-wrapper :field="confirmationField" #default="{hasErrors}" mod-hidden-label>
                <vl-checkbox v-model="confirmation" :mod-disabled="zones.length === 0">
                    <span class="checkbox-label">Nieuwe planaanvraag te bevestigen</span>
                    <vl-link :href="confirmationExampleUri" v-if="confirmationExampleUri" icon="file" target="_blank" mod-icon-before>
                        voorbeeld
                    </vl-link>
                </vl-checkbox>
            </kl-field-validation-wrapper>
        </vl-column>
        <vl-column>
            <vl-button type="submit" :mod-disabled="disableSubmit" :mod-loading="isSending" mod-large>Bewaar
                veranderingen
            </vl-button>
        </vl-column>
        <vl-u-spacer mod-small/>
        <vl-column>
            <vl-title tag-name="h2">KLB-zones van {{ displayName }}</vl-title>
        </vl-column>
        <vl-column v-if="zonesFetching">
            <div v-vl-align:center>
                <vl-loader message="De gegevens worden geladen. Even geduld, a.u.b."/>
            </div>
        </vl-column>
        <vl-column v-if="!zonesFetching && zones">
            <kl-draw-zone-map
                :input-geometries="drawZones"
                :mod-disabled="false"
                name="zones"
                ref="klbZonesMap"
                rules=""
                :show-map="true"
                :mod-enable-multi-zones="true"
                :mod-show-km-hm-toggle="false"
                :for-klb-zones="true"
                :include-imkl="false"
                :mod-maprequest-zone="false"
                :show-klb-archived-zone-toggle="true"
                :klb-archived-zone-toggle-value="alsoSearchArchivedZones"
                @toggle-show-active="search"
            />
        </vl-column>
    </form>
<!--</vl-form-validation-observer>-->
