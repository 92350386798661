import { computed, defineComponent, getCurrentInstance, ref, watch } from 'vue'

export default defineComponent({
  name: 'VlOlMapSource',
  setup() {
    const source = ref(null)
    const layer = computed(() => {
      return getCurrentInstance()?.parent?.data.layer
    })

    watch(
      () => source.value,
      (source) => {
        if (layer.value) {
          ;(layer.value as any).source = source
        } else {
          console.warn('Please make sure the direct parent is a vl-ol-map-layer element')
        }
      }
    )
  }
})
