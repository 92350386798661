<div :class="queueCssClass(serviceInfo)" role="alert">
    <div class="vl-alert__content">
        <template v-if="showLink">
            <kl-router-link protect class="kl-performance-data__infotext" :to="routeAdminServiceDetail" :params="{name:serviceInfo.name}">
                <div class="kl-performance-data__infotext__value">{{ serviceInfo.activeMessageCount }}</div>
                <div class="kl-performance-data__infotext__text">{{ serviceInfo.name }}</div>
                <div class="kl-performance-data__dead-letters" v-if="serviceInfo.deadLetterMessageCount > 0">{{ serviceInfo.deadLetterMessageCount }} deadletters</div>
                <div class="kl-performance-data__service-down" v-if="isServiceDown(serviceInfo)">Service Down!</div>
                <div class="kl-performance-data__service-down" v-for="ss in unhealthySubServices(serviceInfo)">{{ss.name}} Down</div>
            </kl-router-link>
        </template>
        <template v-else>
            <div class="kl-performance-data__infotext kl-performance-data__infotext__disabled">
                <div class="kl-performance-data__infotext__value">{{ serviceInfo.activeMessageCount }}</div>
                <div class="kl-performance-data__infotext__text">{{ serviceInfo.name }}</div>
            </div>
        </template>
    </div>
</div>
