import { MapRequestNotInvolvedInput, ConfirmMapRequestsInput, MapRequestPmklMapAssetsStatus, ImklDataValidationStatus, MapRequestDetailUnaStatus, ValidationStep } from '@/api/klip-api.proxy';
import { EAlertType } from '@/app/shared/state/AlertModule';
import { organisationSettings } from '@/app/settings/settings-routes';
import { useUnaStore } from '../../una-store';
import {useUserStore} from '@/app/shared/state/UserDataModule';
import {EUserRole} from '@/app/shared/state/user-role';
import { EEventType, useSignalrHub } from '@/plugins/signalr';
import KlSteps from '@/app/una/components/kl-steps/kl-steps.vue';
import { useErrorStore } from '@/stores/error-store';
import { AdminMapRequestDetailAsUna } from '@/app/admin/admin-routes';
import { computed, defineComponent, onBeforeUnmount, onMounted, ref } from 'vue';
import { useRoute, useRouter } from '@/router/router-vue3';
import { useKlipApiProxy } from '@/plugins/proxy-client';
import { useAlertHandler } from '@/plugins/alert';
import {usePermission} from '@/plugins/permission';
import {VlButton} from '@govflanders/vl-ui-design-system-vue3';
import {RouteLocation, RouteRecordRaw} from 'vue-router';
import DateUtil from '@/app/shared/helpers/date-util';

export default defineComponent({
    name: 'KlUnaAnswer',
    components: { KlSteps, VlButton },
    emits: ['status'],
    props: {
        status: {
            type: Number as () => MapRequestDetailUnaStatus,
            required: true
        },
        lastStatusChange: {
            type: String,
            required: false,
            default: undefined,
        },
        mapRequestId: {
            type: String,
            required: true
        },
        zoneId: {
            type: String,
            required: true
        },
        zoneName: {
            type: String,
            required: true
        },
        isArchived: {
            type: Boolean,
            required: true
        },
        isClosed: {
            type: Boolean,
            required: false,
            default: false
        },
        downloadArchiveUrl: {
            type: String,
            required: false,
            default: undefined,
        },
        downloadImklUrl: {
            type: String,
            required: false,
            default: undefined,
        }
    },
    setup(props, { emit }) {

        const route = useRoute();
        const router = useRouter();

        const imklRequirementsLink = import.meta.env.VITE_LINKS_IMKLINFO;

        const confirmSending = ref<boolean>(false);
        const verifyingNamespace = ref<boolean>(false);
        const notInvolvedSending = ref<boolean>(false);
        const mapAssetsLoading = ref<boolean>(true);
        const mapAssetsReady = ref<boolean>(false);
        const showUpload = ref<boolean>(false);
        const isProcessing = ref<boolean>(false);
        const isValidating = ref<boolean>(false);
        const namespace = ref<string>('');
        const geometryBlobname = ref<string>('');
        const validationSteps = ref<Array<ValidationStep>>(null);
        const hasReceivedValidateImklUploadTask = ref<boolean>(false);
        const dateStatusChangedFromUi = ref<string>(null);


        const isUnaManager = computed((): boolean => {
            return usePermission().hasPermission([EUserRole.una_manager]);
        })

        const unaSettingsLocation = computed((): RouteRecordRaw => {
            // return { name: organisationSettings.name, hash: organisationSettings.hash };
            return organisationSettings;
        });

        const currentState = computed((): MapRequestDetailUnaStatus => {
            return props.status;
        });

        const dateLastStatusChange = computed(() => {
            if (!!dateStatusChangedFromUi.value) {
                return dateStatusChangedFromUi.value;
            }
            return DateUtil.formatDateTime(props.lastStatusChange);
        });

        const hasDateLastStatusChange = computed(() => {
            return !!props.lastStatusChange;
        });

        const canAnswer = computed((): boolean => {
            return !showUpload.value && !isProcessing.value && !props.isClosed;
        });

        const canConfirm = computed((): boolean => {
            return isStateAssigned.value && !props.isArchived && isUnaWriter.value;
        });

        const isStateAssigned = computed((): boolean => {
            return props.status === MapRequestDetailUnaStatus.Assigned;
        });

        const isStateConfirmed = computed((): boolean => {
            return props.status === MapRequestDetailUnaStatus.Confirmed;
        });

        const isStateConfirmedInvalidImkl = computed((): boolean => {
            return props.status === MapRequestDetailUnaStatus.ConfirmedInvalidImkl;
        });

        const isStateConfirmedUploadedImkl = computed((): boolean => {
            return props.status === MapRequestDetailUnaStatus.ConfirmedUploadedImkl;
        });

        const isStateNotAnswered = computed((): boolean => {
            return props.status === MapRequestDetailUnaStatus.NotAnswered;
        });

        const isStateNotInvolved = computed((): boolean => {
            return props.status === MapRequestDetailUnaStatus.NotInvolved;
        });

        const isStateResponded = computed((): boolean => {
            _isMapReady(); // ???? async backend status fetch in computed ????
            return props.status === MapRequestDetailUnaStatus.Responded;
        });

        const isStateShouldRespondToMri = computed((): boolean => {
            _isMapReady(); // ???? async backend status fetch in computed ????
            return props.status === MapRequestDetailUnaStatus.ShouldRespondToMri;
        });

        const classes = computed(() => {
            if (isStateAssigned.value || (isStateConfirmed.value && !showUpload.value)) {
                return 'vl-u-highlight-content';
            }
            return '';
        });

        const hasNamespace = computed(() => {
            return !!namespace.value;
        });

        const isUnaWriter = computed((): boolean => {
            return useUserStore().hasRole([EUserRole.una_operator]) || viewingAsImpersonatedUser.value;
        });

        const imklUploadUrl = computed((): string => {
            return `/bff/Una/maprequests/imkl/upload/${props.mapRequestId}/${props.zoneId}`;
        });

        const viewingAsImpersonatedUser = computed(() => {
            return route.name === AdminMapRequestDetailAsUna.name;
        });


        const verifyNamespace = () => {
            if (!namespace.value) {
                verifyingNamespace.value = true;
                useKlipApiProxy().una_GetZoneNamespace(props.zoneId).then((response) => {
                    namespace.value = response.result;
                }).finally(() => {
                    _namespaceVerified();
                });
            } else {
                _namespaceVerified();
            }
        }

        const _namespaceVerified = () => {
            verifyingNamespace.value = false;
            showUpload.value = true;
        }

        const _isMapReady = () => {
            useKlipApiProxy().una_GetPmklAssetsStatus(props.mapRequestId, props.zoneId)
                .then((response) => {
                    if (!response && !response.result.mapAssetsStatus) {
                        return;
                    }
                    mapAssetsReady.value = response.result.mapAssetsStatus === MapRequestPmklMapAssetsStatus.Done;
                })
                .finally(() => {
                    mapAssetsLoading.value = false;
                });
        }

        const replaceIMKL = () => {
            verifyNamespace();
            emit('status', MapRequestDetailUnaStatus.Confirmed);
        }

        const cancelUpload = () => {
            showUpload.value = false;
        }

        const confirm = () => {
            confirmSending.value = true;
            useAlertHandler().removeAlert('kl-una-answer_confirmed');
            _confirmMapRequests([new ConfirmMapRequestsInput({ zoneId: props.zoneId, mapRequestId: props.mapRequestId })])
                .then().catch((error) => {
                    useAlertHandler().addAlert({
                        id: 'kl-una-answer_confirmed',
                        title: 'Server fout',
                        message: `We krijgen volgende status terug: <strong>${error.message ? error.message : error.status}<strong>`,
                        closable: true,
                        type: EAlertType.Error,
                    });
                });
        }

        const notInvolved = () => {
            const input = new MapRequestNotInvolvedInput(
                { mapRequestId: props.mapRequestId, zoneId: props.zoneId },
            );

            notInvolvedSending.value = true;
            useAlertHandler().removeAlert('kl-una-answer_notInvolved');
            _replyNotInvolved(input).then(() => {
                emit('status', MapRequestDetailUnaStatus.NotInvolved);
                dateStatusChangedFromUi.value = DateUtil.formatDateTime(DateUtil.now());
            }).catch((error) => {
                useAlertHandler().addAlert({
                    id: 'kl-una-answer_notInvolved',
                    title: 'Server fout',
                    message: `We krijgen volgende status terug: <strong>${error.message ? error.message : error.status}<strong>`,
                    closable: true,
                    type: EAlertType.Error,
                });
            }).finally(() => {
                notInvolvedSending.value = false;
            });
        }

        onMounted(() => {
            _subscribeToHub();

            if (props.status === MapRequestDetailUnaStatus.Confirmed && !props.isArchived) {
                _fetchFileStatus();
            }
        });

        onBeforeUnmount(() => {
            useSignalrHub().unsubFromEvent(EEventType.MapRequestConfirmationReceivedEvent);
            useSignalrHub().unsubFromEvent(EEventType.ValidateImklUploadTask);
            useSignalrHub().unSubscribeFromMapRequestNotifications(props.mapRequestId);
        });

        const _subscribeToHub = () => {
            if (!props.isArchived) {
                if (props.status === MapRequestDetailUnaStatus.Assigned) {
                    useSignalrHub().subToEvent(EEventType.MapRequestConfirmationReceivedEvent, _processMessageResponseMapRequestConfirmationReceivedEvent);
                    useSignalrHub().subscribeToMapRequestNotifications(props.mapRequestId);
                } else if (props.status === MapRequestDetailUnaStatus.Confirmed) {
                    useSignalrHub().subToEvent(EEventType.ValidateImklUploadTask, _processMessageResponseValidateImklUploadTask);
                    useSignalrHub().subscribeToMapRequestNotifications(props.mapRequestId);
                }
            }
        }

        const _processMessageResponseMapRequestConfirmationReceivedEvent = (message: IMessage) => {
            if (message.messageType === EEventType.MapRequestConfirmationReceivedEvent && message.info == props.zoneId) {
                useSignalrHub().unsubFromEvent(EEventType.MapRequestConfirmationReceivedEvent);
                useSignalrHub().subToEvent(EEventType.ValidateImklUploadTask, _processMessageResponseValidateImklUploadTask);

                confirmSending.value = false;
                //props.status = MapRequestDetailUnaStatus.Confirmed;
                emit('status', MapRequestDetailUnaStatus.Confirmed);
            }
        }

        const _processMessageResponseValidateImklUploadTask = (message: IMessage) => {
            if (message.messageType === EEventType.ValidateImklUploadTask && !hasReceivedValidateImklUploadTask.value && message.info == props.zoneId) {
                hasReceivedValidateImklUploadTask.value = true;
                _fetchFileStatus();
            }
        }

        const navigateToViewer = () => {
            const routeData = router.resolve({ name: 'unaviewer', params: { mapRequestId: props.mapRequestId, zoneId: props.zoneId } });
            window.open(routeData.href, '_blank');
        }

        const _fetchFileStatus = async () => {
            isProcessing.value = true;
            // The page error handling should be bypassed in this case because the following errors are by design
            useErrorStore().setBypassError(['404']);
            await useKlipApiProxy().una_GetImklFileUploadStatus(props.mapRequestId, props.zoneId).then((response) => {
                useErrorStore().resetBypassError();
                if (response.statusCode === 200) {
                    if (response.result.status === ImklDataValidationStatus.Valid) {
                        emit('status', MapRequestDetailUnaStatus.Responded);
                        dateStatusChangedFromUi.value = DateUtil.formatDateTime(DateUtil.now());
                    } else if (response.result.status === ImklDataValidationStatus.Uploaded) {
                        emit('status', MapRequestDetailUnaStatus.ConfirmedUploadedImkl);
                    } else {
                        validationSteps.value = response.result.validationSteps.sort((a, b) => a.order - b.order);
                        emit('status', MapRequestDetailUnaStatus.ConfirmedInvalidImkl);
                    }
                } else {
                    showUpload.value = true;
                }
                isProcessing.value = false;
                // Status code 404 and 202 are considered errors and are treated in the catch
            }).catch((error) => {
                useErrorStore().resetBypassError();
                if (error.statusCode && error.statusCode === 404) {
                    isProcessing.value = false;
                } else if (error.statusCode && error.statusCode === 202) {
                    isValidating.value = true;
                    verifyNamespace();
                    emit('status', MapRequestDetailUnaStatus.ConfirmedUploadedImkl);
                }
            }).finally(() => {
                useErrorStore().resetBypassError();
                hasReceivedValidateImklUploadTask.value = false;
            });
        }

        const _confirmMapRequests = (input: ConfirmMapRequestsInput[]) => useUnaStore().confirmMapRequests(input);
        const _replyNotInvolved = (input: MapRequestNotInvolvedInput) => useUnaStore().notInvolved(input);

        return {
            confirmSending,
            verifyingNamespace,
            notInvolvedSending,
            mapAssetsLoading,
            mapAssetsReady,
            showUpload,
            isProcessing,
            isValidating,
            namespace,
            geometryBlobname,
            validationSteps,
            hasReceivedValidateImklUploadTask,
            dateStatusChangedFromUi,
            imklRequirementsLink,
            verifyNamespace,
            replaceIMKL,
            cancelUpload,
            confirm,
            notInvolved,
            isUnaManager,
            unaSettingsLocation,
            currentState,
            dateLastStatusChange,
            hasDateLastStatusChange,
            canAnswer,
            canConfirm,
            isStateAssigned,
            isStateConfirmed,
            isStateConfirmedInvalidImkl,
            isStateConfirmedUploadedImkl,
            isStateNotAnswered,
            isStateNotInvolved,
            isStateResponded,
            isStateShouldRespondToMri,
            classes,
            hasNamespace,
            isUnaWriter,
            imklUploadUrl,
            viewingAsImpersonatedUser,
            navigateToViewer,
        };
    }
})
