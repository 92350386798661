import { register } from 'ol/proj/proj4'
import proj4 from 'proj4'
import { computed, defineComponent, inject, reactive, Ref, watch } from 'vue'
import VectorSource from 'ol/source/Vector'
import { GeoJSON } from 'ol/format'
import OlGML2 from 'ol/format/GML2'
import { bbox as bboxStrategy } from 'ol/loadingstrategy'
import { Cluster, Source } from 'ol/source'
import { Point } from 'ol/geom'
import Feature from 'ol/Feature'

proj4.defs(
  'EPSG:31370',
  '+proj=lcc +lat_1=51.16666723333333 +lat_2=49.8333339 +lat_0=90 +lon_0=4.367486666666666 ' +
    '+x_0=150000.013 +y_0=5400088.438 +ellps=intl +towgs84=-106.869,52.2978,-103.724,0.3366,-0.457,1.8422,-1.2747 +units=m +no_defs'
)
register(proj4)

export default defineComponent({
  name: 'VlOlMapVectorSource',
  compatConfig: {
    MODE: 3
  },
  props: {
    url: {
      type: String,
      default: ''
    },
    features: {
      type: Array<Feature>,
      default: null
    },
    version: {
      type: String,
      default: ''
    },
    service: {
      type: String,
      default: ''
    },
    layers: {
      type: Array,
      default: null
    },
    clustering: Boolean,
    clusterDistance: {
      type: Number,
      default: 40
    },
    sourceProjection: {
      type: String,
      default: 'EPSG:31370'
    }
  },
  setup(props, { emit }) {
    const map: Ref = inject('olMap')
    const source: Ref = inject('source')

    const capabilities = reactive({
      version: undefined,
      service: 'WFS',
      typename: undefined,
      layers: undefined
    })

    const params = computed(() => {
      return {
        request: 'GetFeature',
        outputFormat: 'GML2',
        version: props.version || capabilities.version,
        service: props.service || capabilities.service,
        typename: props.layers || capabilities.layers
      }
    })

    function initializeVectorSource() {
      const vectorSource = new VectorSource({
        url: (extent, resolution, projection) => {
          const code = projection.getCode()
          const bbox = proj4(code, map.value.get, [...extent]).join(',')
          return (
            `${props.url}?service=${params.value.service}&request=${params.value.request}&typename=${params.value.typename}` +
            `&bbox=${bbox}&srsname=${code}&outputFormat=${params.value.outputFormat}&version=${params.value.version},${code}`
          )
        },
        format: new OlGML2(),
        strategy: bboxStrategy
      })

      setVectorSource(vectorSource)
    }

    function setVectorSource(vectorSource: Source) {
      if (props.clustering) {
        source.value = new Cluster({
          source: vectorSource as VectorSource,
          distance: props.clusterDistance || 40,
          geometryFunction: (feature) => new Point((feature.getGeometry() as any).getFirstCoordinate())
        })
      } else {
        source.value = vectorSource
      }
    }

    watch(
      () => params.value,
      () => {
        initializeVectorSource()
      },
      { deep: true }
    )

    watch(
      () => props.features,
      (features) => {
        if (features) {
          if (!source.value) {
            setVectorSource(new VectorSource({ features }))
          } else {
            source.value.clear()
            source.value.addFeatures(features)
          }
        }
      }
    )

    watch(
      () => props.url,
      async (url) => {
        if (!url) return
        const isGeoJSON = url.endsWith('geojson')
        if (isGeoJSON) {
          await createGeoJSONVectorSource(url)
        } else {
          const caps = await getCapabilities()
          if (caps) {
            try {
              const serviceIdentification = caps.getElementsByTagName('ows:ServiceIdentification')[0]
              const serviceTypeVersion = serviceIdentification.getElementsByTagName('ows:ServiceTypeVersion')[0].childNodes[0].nodeValue
              const featuresTypeList = caps.getElementsByTagName('FeatureTypeList')
              const featureTypes = [...featuresTypeList].map((featureType) => featureType.getElementsByTagName('FeatureType')[0])
              const layers = [...featureTypes].map((featureType) => {
                return featureType.getElementsByTagName('Name')[0].childNodes[0].nodeValue
              })
              capabilities.version = serviceTypeVersion
              capabilities.layers = layers
            } catch (error) {
              console.error('The capabilities identification service processing went wrong!', error)
            }
          }
        }
      },
      { immediate: true }
    )

    async function createGeoJSONVectorSource(url) {
      if (GeoJSON && VectorSource) {
        try {
          const response = await fetch(url)
          if (response.ok) {
            const geojson = await response.json()
            const features = new GeoJSON().readFeatures(geojson)
            const projectionCode = map.value.getView().getProjection().getCode()
            features.forEach((feature) => {
              feature.getGeometry().transform(props.sourceProjection, projectionCode)
            })
            setVectorSource(
              new VectorSource({
                features
              })
            )
          }
        } catch (error) {
          console.error('The GeoJSON vector source processing went wrong!', error)
        }
      }
    }

    async function getCapabilities() {
      try {
        const response = await fetch(`${props.url}?request=getcapabilities`)
        if (response.ok) {
          return new DOMParser().parseFromString(await response.text(), 'text/xml')
        }
      } catch (error) {
        console.error('The capabilities processing went wrong!', error)
      }
      return undefined
    }
  }
})
