const iconData = {
  src: `
         data:image/svg+xml;base64,
         PHN2ZyB3aWR0aD0iMjAiIGhlaWdodD0iMjgiIHZpZXdCb3g9IjAgMCAyMCAyOCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6L
         y93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTEyLjYxMTkgMC44NzgxNDVMMTIuNjEzOCAwLjg3ODY1OEMxNC43MD
         Q4IDEuNDQ5MTMgMTYuNTIyNSAyLjcyNjQxIDE3Ljc0NzYgNC40ODMwNkwxNy43NDc1IDQuNDgzMDlMMTcuNzUyMyA0LjQ4OTY
         3QzE4LjY5NDEgNS43OTQ0NCAxOS4yNjQyIDcuMzIyMDIgMTkuNDA0MyA4LjkxNDcxTDE5LjQwNDYgOC45MTc3MUMxOS42MDMy
         IDExLjAyODUgMTkuMDY5IDEzLjE0NCAxNy44ODgxIDE0LjkxOTRMMTcuODg4MSAxNC45MTk0TDE3Ljg4NCAxNC45MjU3QzE2L
         jM3MDQgMTcuMjc3MiAxNC44NTcxIDE5LjY2NzcgMTMuNDM0OCAyMi4wODcyQzEyLjc3NDkgMjMuMTk5MSAxMi4xODMyIDI0Lj
         M1ODEgMTEuNTk5NCAyNS41MDE2QzExLjM3NDkgMjUuOTQxMyAxMS4xNTE2IDI2LjM3ODggMTAuOTI2IDI2LjgxMDRMMTAuOTE
         0OCAyNi44MzE4TDEwLjkwNTggMjYuODU0MUMxMC43NzYgMjcuMTc0MSAxMC40ODE2IDI3LjQwNzMgMTAuMTI2OCAyNy40NjFM
         MTAuMTI2OCAyNy40NjFMMTAuMTIxNyAyNy40NjE4QzkuOTYzNDEgMjcuNDg3NCA5LjgwMDg0IDI3LjQ2OTYgOS42NTIxOCAyN
         y40MTA1QzkuNTA0NzkgMjcuMzUyIDkuMzc3NDggMjcuMjU1MiA5LjI4MzY3IDI3LjEzMTdDOS4xOTI2NiAyNy4wMDg3IDkuMT
         E0MTcgMjYuODc3IDkuMDQ5MjMgMjYuNzM4N0w5LjA0NTU2IDI2LjczMDlMOS4wNDE2MiAyNi43MjMyQzguOTI5MTcgMjYuNTA
         zOSA4LjgxNjc3IDI2LjI4NDIgOC43MDQyOCAyNi4wNjQ0QzcuMzA3MjMgMjMuMzM0NiA1Ljg5Njg4IDIwLjU3ODggNC4yMTcw
         NCAxNy45NTIyTDQuMjE2NDYgMTcuOTUxM0MzLjY1MzMxIDE3LjA3NDggMy4wNTY3NyAxNi4yMTcgMi40NDQ0NiAxNS4zNzgzT
         DIuNDQ0NTQgMTUuMzc4M0wyLjQzODcxIDE1LjM3MDZDMS41MDc3MSAxNC4xNDU2IDAuODk2MDYxIDEyLjcxNjEgMC42NTYzMz
         EgMTEuMjA2NEwwLjY1NjM1MiAxMS4yMDY0TDAuNjU1NTg3IDExLjIwMThDMC4yNDE0ODcgOC43NDA4MSAwLjg4MzYwMiA2LjI
         xOTk3IDIuNDMxMDMgNC4yMzk1MUwyLjQzMTA5IDQuMjM5NTZMMi40MzY1MiA0LjIzMjM0QzMuODQxMjYgMi4zNjU5MiA1Ljkx
         NDA0IDEuMDg5NjMgOC4yMzk0NSAwLjY2M0w4LjIzOTQ2IDAuNjYzMDYzTDguMjQ3MzMgMC42NjE0ODlDOC40Nzk2NSAwLjYxN
         TAwMiA4LjcwMTg1IDAuNTg0Mjk2IDguOTQwMzYgMC41NTEzMzVDOS4wNTU0OSAwLjUzNTQyNSA5LjE3NDQzIDAuNTE4OTg5ID
         kuMzAwMTMgMC41TDEwLjgxMjggMC41MDAwMDZDMTEuMDE2OSAwLjU0MTk2MiAxMS4yMTMyIDAuNTc4OTg5IDExLjQwNDYgMC4
         2MTUwOThDMTEuODIgMC42OTM0NDQgMTIuMjEyNCAwLjc2NzQ2OSAxMi42MTE5IDAuODc4MTQ1WiIgZmlsbD0iIzAwNTVDQyIg
         c3Ryb2tlPSIjMDAzQjhFIi8+CjxwYXRoIGQ9Ik0xMC4wMDAxIDEzLjk3NDNDMTEuMTI4NCAxMy45Njk4IDEyLjIwODggMTMuN
         TI0OCAxMy4wMDM2IDEyLjczNzJDMTMuNzk4MiAxMS45NDk1IDE0LjI0MjEgMTAuODgzNyAxNC4yMzc2IDkuNzczOTlDMTQuMj
         MzMSA4LjY2NDU1IDEzLjc4MDMgNy42MDIxNyAxMi45NzkzIDYuODIwODZDMTIuMTc4MiA2LjAzOTMyIDExLjA5NDMgNS42MDI
         3NyA5Ljk2NTk5IDUuNjA3MjFDOC44Mzc0NSA1LjYxMTY2IDcuNzU3MDMgNi4wNTY4OSA2Ljk2MjQ1IDYuODQ0NTVDNi4xNjc2
         NCA3LjYzMjIgNS43MjM5MiA4LjY5ODA2IDUuNzI4NDMgOS44MDc1MUM1LjczNjUzIDEwLjkxNiA2LjE5MDI3IDExLjk3NjMgN
         i45OTA1NiAxMi43NTcxQzcuNzkwODcgMTMuNTM3NyA4Ljg3MjcgMTMuOTc1MiAxMCAxMy45NzQzSDEwLjAwMDFaIiBmaWxsPS
         J3aGl0ZSIvPgo8L3N2Zz4K
       `,
  highlight: `
           data:image/svg+xml;base64,
           PHN2ZyB3aWR0aD0iMjgiIGhlaWdodD0iNDAiIHZpZXdCb3g9IjAgMCAyOCA0MCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6L
           y93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTE3LjcwNzYgMS4wNDcyOEwxNy43MDk1IDEuMDQ3ODFDMjAuNjc5NS
           AxLjg3NDY0IDIzLjI2NDMgMy43MjY3MiAyNS4wMDggNi4yNzc5TDI1LjAwOCA2LjI3NzkzTDI1LjAxMjYgNi4yODQ1MUMyNi4
           zNTMyIDguMTc5NjYgMjcuMTY1NSAxMC4zOTk3IDI3LjM2NTIgMTIuNzE1NkwyNy4zNjU1IDEyLjcxODZDMjcuNjQ4MyAxNS43
           ODU3IDI2Ljg4NzQgMTguODU4NiAyNS4yMDczIDIxLjQzNkwyNS4yMDczIDIxLjQzNkwyNS4yMDMzIDIxLjQ0MjNDMjMuMDg0N
           yAyNC44MDExIDIwLjk2NzggMjguMjEzMyAxOC45Nzg3IDMxLjY2NTlDMTguMDU4NCAzMy4yNDgxIDE3LjIzNDggMzQuODk0NC
           AxNi40MTg1IDM2LjUyNThDMTYuMTAzNSAzNy4xNTU0IDE1Ljc4OTcgMzcuNzgyOCAxNS40NzE3IDM4LjQwMzRMMTUuNDYwOSA
           zOC40MjQ2TDE1LjQ1MjEgMzguNDQ2OEMxNS4yNDEyIDM4Ljk3NzUgMTQuNzY3IDM5LjM1NTUgMTQuMjA1OSAzOS40NDIxTDE0
           LjIwMDcgMzkuNDQyOUMxMy45NDUzIDM5LjQ4NTEgMTMuNjgzIDM5LjQ1NTggMTMuNDQyNSAzOS4zNTg0QzEzLjIwMzIgMzkuM
           jYxNCAxMi45OTQ1IDM5LjEgMTIuODM5OCAzOC44OTE4QzEyLjcwMzkgMzguNzA0NCAxMi41ODY2IDM4LjUwMzcgMTIuNDg5NS
           AzOC4yOTI4TDEyLjQ4NiAzOC4yODUxTDEyLjQ4MjEgMzguMjc3NEMxMi4zMjQ5IDM3Ljk2NDQgMTIuMTY3OCAzNy42NTExIDE
           yLjAxMDYgMzcuMzM3N0MxMC4wNTM4IDMzLjQzNjIgOC4wODM4MiAyOS41MDg0IDUuNzM3OCAyNS43NjUzTDUuNzM3MjQgMjUu
           NzY0NEM0Ljk1MTU4IDI0LjUxNjcgNC4xMTg4OSAyMy4yOTQ4IDMuMjYzNTIgMjIuMDk5M0wzLjI2MzYgMjIuMDk5MkwzLjI1N
           zg4IDIyLjA5MTVDMS45MzM2OCAyMC4zMTM2IDEuMDYyOTIgMTguMjM3NyAwLjcyMTU5OSAxNi4wNDQzTDAuNzIwODYzIDE2Lj
           AzOThDMC4xMzA3MzYgMTIuNDYxIDEuMDQ2MzQgOC43OTcwMiAzLjI0ODgzIDUuOTIwNjRMMy4yNDg4OSA1LjkyMDY5TDMuMjU
           0MjMgNS45MTM0NUM1LjI1Mjc2IDMuMjAzODkgOC4xOTkxOCAxLjM1NDE2IDExLjUwMDkgMC43MzYwNDRMMTEuNTAwOSAwLjcz
           NjExTDExLjUwODkgMC43MzQ0NzVDMTEuODM5NSAwLjY2Njk4OCAxMi4xNjAxIDAuNjIxNzM5IDEyLjQ5OCAwLjU3NDA2NUMxM
           i42NjIyIDAuNTUwODk0IDEyLjgzMDUgMC41MjcxNDkgMTMuMDA1OSAwLjVMMTUuMTU3MyAwLjUwMDAwOEMxNS40NDI1IDAuNT
           YwMDI4IDE1LjcxOTcgMC42MTM0MTIgMTUuOTkxOCAwLjY2NTgwNUMxNi41NzgyIDAuNzc4NzE4IDE3LjE0MDcgMC44ODcwMzQ
           gMTcuNzA3NiAxLjA0NzI4WiIgZmlsbD0iIzAwNTVDQyIgc3Ryb2tlPSIjMDAzQjhFIi8+CjxwYXRoIGQ9Ik0xNC4wMzIgMjJD
           MTYuMTUzNiAyMS45OTE1IDE4LjE4NTEgMjEuMTQwNSAxOS42Nzk2IDE5LjYzNDRDMjEuMTczNiAxOC4xMjgyIDIyLjAwODQgM
           TYuMDkgMjEuOTk5OSAxMy45NjhDMjEuOTkxNCAxMS44NDY0IDIxLjE0IDkuODE0OTEgMTkuNjMzOSA4LjMyMDg3QzE4LjEyNz
           cgNi44MjYzNyAxNi4wODk1IDUuOTkxNTggMTMuOTY4IDYuMDAwMDZDMTEuODQ2IDYuMDA4NTcgOS44MTQ0NCA2Ljg1OTk2IDg
           uMzIwNCA4LjM2NjE2QzYuODI1OTEgOS44NzIzNSA1Ljk5MTU4IDExLjkxMDUgNi4wMDAwNiAxNC4wMzIxQzYuMDE1MjkgMTYu
           MTUxOCA2Ljg2ODQ3IDE4LjE3OTMgOC4zNzMyNiAxOS42NzI0QzkuODc4MDcgMjEuMTY1MiAxMS45MTIyIDIyLjAwMTcgMTQuM
           DMxOSAyMkgxNC4wMzJaIiBmaWxsPSJ3aGl0ZSIvPgo8L3N2Zz4K
         `,

  clusterIcon: {
    s: `
           data:image/svg+xml;base64,
           PHN2ZyB3aWR0aD0iNDYiIGhlaWdodD0iNDYiIHZpZXdCb3g9IjAgMCA0NiA0NiIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA
           6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTAgMjNDMCAxMC4yOTc1IDEwLjI5NzUgMCAyMyAwVjBDMzUuNz
           AyNSAwIDQ2IDEwLjI5NzUgNDYgMjNWMjNDNDYgMzUuNzAyNSAzNS43MDI1IDQ2IDIzIDQ2VjQ2QzEwLjI5NzUgNDYgMCAzN
           S43MDI1IDAgMjNWMjNaIiBmaWxsPSIjMDA1NUNDIiBmaWxsLW9wYWNpdHk9IjAuMyIvPgo8cmVjdCB4PSI0IiB5PSI0IiB3
           aWR0aD0iMzgiIGhlaWdodD0iMzgiIHJ4PSIxOSIgZmlsbD0iIzAwNTVDQyIgZmlsbC1vcGFjaXR5PSIwLjYiLz4KPHJlY3Q
           geD0iOCIgeT0iOCIgd2lkdGg9IjMwIiBoZWlnaHQ9IjMwIiByeD0iMTUiIGZpbGw9IiMwMDU1Q0MiLz4KPC9zdmc+Cg==
         `,
    m: `
           data:image/svg+xml;base64,
           PHN2ZyB3aWR0aD0iNTYiIGhlaWdodD0iNTYiIHZpZXdCb3g9IjAgMCA1NiA1NiIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA
           6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTAgMjhDMCAxMi41MzYgMTIuNTM2IDAgMjggMEM0My40NjQgMC
           A1NiAxMi41MzYgNTYgMjhDNTYgNDMuNDY0IDQzLjQ2NCA1NiAyOCA1NkMxMi41MzYgNTYgMCA0My40NjQgMCAyOFoiIGZpb
           Gw9IiMwMDU1Q0MiIGZpbGwtb3BhY2l0eT0iMC4zIi8+CjxyZWN0IHg9IjQiIHk9IjQiIHdpZHRoPSI0OCIgaGVpZ2h0PSI0
           OCIgcng9IjI0IiBmaWxsPSIjMDA1NUNDIiBmaWxsLW9wYWNpdHk9IjAuNiIvPgo8cmVjdCB4PSI4IiB5PSI4IiB3aWR0aD0
           iNDAiIGhlaWdodD0iNDAiIHJ4PSIyMCIgZmlsbD0iIzAwNTVDQyIvPgo8L3N2Zz4K
         `,
    l: `
           data:image/svg+xml;base64,
           PHN2ZyB3aWR0aD0iNjYiIGhlaWdodD0iNjYiIHZpZXdCb3g9IjAgMCA2NiA2NiIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA
           6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTAgMzNDMCAxNC43NzQ2IDE0Ljc3NDYgMCAzMyAwQzUxLjIyNT
           QgMCA2NiAxNC43NzQ2IDY2IDMzQzY2IDUxLjIyNTQgNTEuMjI1NCA2NiAzMyA2NkMxNC43NzQ2IDY2IDAgNTEuMjI1NCAwI
           DMzWiIgZmlsbD0iIzAwNTVDQyIgZmlsbC1vcGFjaXR5PSIwLjMiLz4KPHJlY3QgeD0iNCIgeT0iNCIgd2lkdGg9IjU4IiBo
           ZWlnaHQ9IjU4IiByeD0iMjkiIGZpbGw9IiMwMDU1Q0MiIGZpbGwtb3BhY2l0eT0iMC42Ii8+CjxyZWN0IHg9IjgiIHk9Ijg
           iIHdpZHRoPSI1MCIgaGVpZ2h0PSI1MCIgcng9IjI1IiBmaWxsPSIjMDA1NUNDIi8+Cjwvc3ZnPgo=
         `,
    xl: `
           data:image/svg+xml;base64,
           PHN2ZyB3aWR0aD0iODEiIGhlaWdodD0iODEiIHZpZXdCb3g9IjAgMCA4MSA4MSIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA
           6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTAgNDAuNUMwIDE4LjEzMjUgMTguMTMyNSAwIDQwLjUgMFYwQz
           YyLjg2NzUgMCA4MSAxOC4xMzI1IDgxIDQwLjVWNDAuNUM4MSA2Mi44Njc1IDYyLjg2NzUgODEgNDAuNSA4MVY4MUMxOC4xM
           zI1IDgxIDAgNjIuODY3NSAwIDQwLjVWNDAuNVoiIGZpbGw9IiMwMDU1Q0MiIGZpbGwtb3BhY2l0eT0iMC4zIi8+CjxyZWN0
           IHg9IjQiIHk9IjQiIHdpZHRoPSI3MyIgaGVpZ2h0PSI3MyIgcng9IjM2LjUiIGZpbGw9IiMwMDU1Q0MiIGZpbGwtb3BhY2l
           0eT0iMC42Ii8+CjxyZWN0IHg9IjgiIHk9IjgiIHdpZHRoPSI2NSIgaGVpZ2h0PSI2NSIgcng9IjMyLjUiIGZpbGw9IiMwMD
           U1Q0MiLz4KPC9zdmc+Cg==
         `
  }
}

export default iconData
