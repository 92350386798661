import {defineComponent, inject, onMounted, ref} from "vue";
import * as KlipApi from "@/api/klip-api.proxy";
import DateUtil from '@/app/shared/helpers/date-util';

export default defineComponent({
    setup() {
        const proxy = inject<KlipApi.IKlipApiProxy>('klipApiProxy');
        const viewModelLoading = ref<boolean>(false);
        const holidays = ref< { [key: string]: string[]; }>(null);
        const loadHolidays = async () => {
            const response = await proxy.operations_Holidays();
            if (response.isSuccessStatusCode) {
                holidays.value = response.result;
            }
            viewModelLoading.value = false;
        };

        const formatHoliday = (date: string): string => {
            return DateUtil.formatDate(date);
        }

        onMounted(async () => {
            await loadHolidays();
        });

        return {
            viewModelLoading,
            holidays,
            formatHoliday,
        }
    }
});
