import {LocationQuery, RouteLocationGeneric} from "vue-router";
import dayjs from 'dayjs';
import {isEmpty} from 'lodash-es';
import DateUtil from '@/app/shared/helpers/date-util';

export default class QueryFilter {

    private _dateFormat: string = 'DD-MM-YYYY';

    mapFilterFromQuery(route: RouteLocationGeneric, object: any, defaultValues?: any, typeDefinition?: any) {
        // TODO? clone orig object instead of mutating it?
        object = this.addDefaultValues(object, defaultValues);
        const newObject: any = {};
        try {
            if (route.query && object) {
                const query: LocationQuery = route.query;
                Object.keys(query).forEach((key) => {
                    if (key in object) {
                        const queryValue = query[key] as string;
                        if (key.toLowerCase().includes('zone')) {
                            newObject[key] = queryValue.replace(/\\/g, '');
                        } else if (dayjs(queryValue, this._dateFormat, true).isValid()) {
                            // newObject[key] = dayjs(queryValue, this._dateFormat, true).format('YYYY-MM-DD HH:mm:ss');
                            newObject[key] = DateUtil.formatForApi(dayjs(queryValue, this._dateFormat, true), 'YYYY-MM-DD HH:mm:ss');
                        } else if (Array.isArray(newObject[key])) {
                            newObject[key] = queryValue.split(',');
                        } else if (/^(([0-9]*)|(([0-9]*)\.([0-9]*)))$/g.exec(queryValue)) {
                            newObject[key] = parseInt(queryValue, 10);
                        } else if (typeDefinition && typeDefinition[key] === 'boolean') {
                            newObject[key] = queryValue?.toLowerCase() === 'true';
                        } else {
                            newObject[key] = query[key];
                        }
                    }
                });
            }
            // type validation
            if (typeDefinition) {
                Object.keys(typeDefinition).forEach((key) => {
                    if (newObject[key]) {
                        if (typeof typeDefinition[key] === 'object') {
                            if (!typeDefinition[key][newObject[key]]) {
                                throw new Error('query param invalid range');
                            }
                        } else if (typeDefinition[key] === 'boolean') {
                            if (typeof newObject[key] !== 'boolean') {
                                throw new Error('query param invalid boolean');
                            }
                        } else if (typeDefinition[key] === 'Date') {
                            // if (!dayjs(newObject[key], 'YYYY-MM-DD HH:mm:ss', true).isValid()) {
                            //     throw new Error(`query param invalid Date "${key} = ${newObject[key]}"`);
                            // }
                            if (!DateUtil.parseToDayJs(newObject[key])?.isValid()) {
                                throw new Error(`query param invalid Date "${key} = ${newObject[key]}"`);
                            }
                        }
                    }
                });
            }
        } catch (e) {
            console.error(e);
            this.removeQuery(route);
            return object;
        }
        Object.keys(newObject).forEach((key) => {
            object[key] = newObject[key];
        });
        return object;
    }

    addDefaultValues(object: any, defaultValues?: any) {
        if (defaultValues) {
            Object.keys(defaultValues).forEach((key) => {
                object[key] = defaultValues[key];
            });
        }
        return object;
    }

    mapColumnsFromQuery(route: RouteLocationGeneric, columns: any[], type: any) {
        if (route.query) {
            const query = route.query;
            const keyName = type[parseInt(query['orderField'] as string, 10)];
            if (query['orderField'] && keyName) {
                columns.forEach((column) => {
                    if (column.key.toLowerCase() === keyName.toLowerCase() && query['orderDirection']) {
                        const direction = parseInt(query['orderDirection'] as string, 10);
                        if (direction <= 2) {
                            column.direction = direction;
                        }
                    }
                });
            }
        }
    }

    getQuery(object: any): string {
        const result = Object.keys(object)
            .map((key, index, array) => {
                if (isEmpty(object[key])) {
                    return null;
                }
                // ignore these queryParams
                // TODO: refactor & move
                if (['limit', 'zoneId'].includes(key)) {
                    return null;
                }

                if (dayjs(object[key]).isValid()) {
                    return encodeURIComponent(key) + '=' + encodeURIComponent(dayjs(object[key]).format(this._dateFormat));
                } else {
                    return encodeURIComponent(key) + '=' + encodeURIComponent(object[key]);
                }
            }).filter((value) => {
                if (value) {
                    return value;
                }
            })
            .join('&');

        return result;
    }

    mapQuery(route: RouteLocationGeneric, object: any) {
        const query = this.getQuery(object);
        history.pushState(
            {},
            null,
            `${route.path}?${query}${route.hash}`,
        );
    }

    setQuery(route: RouteLocationGeneric, query: string) {
        history.pushState(
            {},
            null,
            route.path +
            query ? '?' + query : ''
                + route.hash,
        );
    }

    removeQuery(route: RouteLocationGeneric) {
        history.pushState(
            {},
            null,
            route.path + route.hash,
        );
    }
}
