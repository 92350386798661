import KlSettings from './settings.vue';
import {klipIndex} from '@/router/klip-index';
import {RouteRecordRaw} from 'vue-router';

export const settings: RouteRecordRaw = {
    path: '/settings',
    name: 'settings',
    component: KlSettings,
    meta: {
        breadcrumb: { title: 'Instellingen', parent: klipIndex },
    },
};

export const organisationSettings: RouteRecordRaw = {
    path: '/settings',
    name: 'settings',
    component: KlSettings,
    meta: {
        breadcrumb: { title: 'Instellingen', parent: klipIndex },
    },
};

export const Profile: RouteRecordRaw = {
    path: '/profile',
    redirect: settings,
};

export default [settings, Profile];

