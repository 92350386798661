import {
    IApplicationMessages,
    IAlertNotification,
    UserImpersonationDto,
    ApplicationMessages
} from '@/api/klip-api.proxy';
import userLocalStorage from '@/storage/user-local-storage';
import { useCodeListStore } from '../../state/CodeListModule';
import { useUserStore } from '@/app/shared/state/UserDataModule';
import {computed, defineComponent, onBeforeUnmount, onMounted, ref} from 'vue';
import {useRoute} from '@/router/router-vue3';
import {EEventType, useSignalrHub} from '@/plugins/signalr';
import {useKlipApiProxy} from '@/plugins/proxy-client';
import {applicationAnnouncementsStorageKey, sessionTimeoutLocalStorageKey} from '@/app/shared/state/AlertModule';
import {useKlModalEventsUtil} from '@/app/shared/helpers/kl-modal-events-util';
import DateUtil from '@/app/shared/helpers/date-util';


export default defineComponent({
    name: 'KlApplicationMessages',
    props: {},
    setup() {
        const route = useRoute();

        const whatsNewModalEvents = useKlModalEventsUtil('whatsnew-modal', {
            onClosed() {
                if (applicationMessages.value?.whatsNew) {
                    userLocalStorage.appendItemToArray(applicationAnnouncementsStorageKey, applicationMessages.value.whatsNew.id);
                }
            },
        });


        const isRequestingForImpersonation = ref<boolean>(false);
        const userImpersonation = ref<UserImpersonationDto>(null);
        const applicationMessages = ref<IApplicationMessages>(null);
        const isSessionTimeoutVisibleValue = ref<boolean>(false);

        const isBeta = computed(() => {
            return useCodeListStore().isBeta;
        });

        const betaMessage = computed((): IAlertNotification => {
            return {
                title: 'Let op! Dit is een test omgeving',
                message: 'Deze omgeving gebruik je enkel om te testen. Een echte planaanvraag doe je op <a href="https://klip.vlaanderen.be">klip.vlaanderen.be</a>',
                isClosable: false,
            };
        });

        const messages = computed((): IAlertNotification[] => {
            if (applicationMessages.value?.messages) {
                return applicationMessages.value.messages;
            }
            return [];
        });

        const whatsNew = computed(() => {
            if (applicationMessages.value) {
                return applicationMessages.value.whatsNew;
            }
            return null;
        });

        const openWhatsNew = computed((): boolean => !!whatsNew.value);

        const isSessionTimeoutVisible = computed(() => {
            return isSessionTimeoutVisibleValue.value;
        });


        const closeMessage = (id: string) => {
            userLocalStorage.appendItemToArray(applicationAnnouncementsStorageKey, id);
            _removeMessage(id);
        }

        const login = () => {
            useSignalrHub().stop(`${window.location.origin}/auth/login?returnUrl=${window.location.pathname}`);
        }

        const _removeAlreadyClosedMessages = (allMessages: ApplicationMessages): ApplicationMessages => {
            if (!allMessages?.messages && !allMessages?.whatsNew) {
                return allMessages;
            }

            const removedMessageIds: string[] = userLocalStorage.getItem(applicationAnnouncementsStorageKey, true) as string[];
            if (!removedMessageIds) {
                return allMessages;
            }

            if (allMessages.messages) {
                allMessages.messages = allMessages.messages.filter((message) => !removedMessageIds.includes(message.id));
            }

            if (allMessages.whatsNew && removedMessageIds.includes(allMessages.whatsNew.id)) {
                allMessages.whatsNew = null;
            }

            return allMessages;
        }

        const _getApplicationMessages = async () => {
            const getApplicationMessagesResponse = await useKlipApiProxy().messages_GetApplicationMessages();
            applicationMessages.value = _removeAlreadyClosedMessages(getApplicationMessagesResponse.result);
        }

        const _checkAskForImpersonation = async () => {
            const userStore = useUserStore();
            const hasPendingImpersonationResult = await useKlipApiProxy().acmIdmAuth_HasPendingImpersonationConsent(userStore.user.klipUserId, userStore.organisationId);
            if(hasPendingImpersonationResult.isSuccessStatusCode && hasPendingImpersonationResult.result) {
                isRequestingForImpersonation.value = true;
                userImpersonation.value = hasPendingImpersonationResult.result;
            }
        }

        const _initApplicationMessagePolling = () => {
            if (!isSessionTimeoutVisibleValue.value) {
                setTimeout(() => {
                    _getApplicationMessages();
                    _initApplicationMessagePolling();
                }, 300000);
            }
        }

        const _initSessionTimeoutPolling = () => {
            setTimeout(() => {
                if (DateUtil.parseToDayJs(useUserStore().user.sessionTimeout) < DateUtil.now()) {
                    userLocalStorage.setItem(sessionTimeoutLocalStorageKey, route.path);
                    // Explicit call to disconnect from signalr
                    useSignalrHub().stop();
                    isSessionTimeoutVisibleValue.value = true;
                } else {
                    _initSessionTimeoutPolling();
                }
            }, 10000);
        }

        const _removeMessage = (id: string) => {
            applicationMessages.value.messages = applicationMessages.value.messages.filter((message) => message.id !== id);
        }

        const consentImpersonation = async () => {
            const userId = useUserStore().user.klipUserId;
            await useKlipApiProxy().acmIdmAuth_ChangeImpersonationConsent(userId, userImpersonation.value.id, true);
        };

        const revokeImpersonation = async () => {
            const userId = useUserStore().user.klipUserId;
            await useKlipApiProxy().acmIdmAuth_ChangeImpersonationConsent(userId, userImpersonation.value.id, false);
        };

        const _subscribeToSignalrEvents = () => {
            const user = useUserStore().user;
            const groupName = user.isCitizen ? user.klipUserId : `${user.klipUserId}_${user.klipOrganisationApiId}`;
            useSignalrHub().subscribeToCustomIdNotifications(groupName, 'AskForImpersonationEvent');
            useSignalrHub().subToEvent(EEventType.AskForImpersonationEvent, _checkAskForImpersonation);
        }

        onMounted(async () => {
            setTimeout(async () => {
                // Hack for vl-modal problem.
                // Problem: grey overlay will disappear if the modal is open when another modal is rendered on the page (kl-locationpicker).
                // Solution: wait until the first page is loaded & rendered.

                // Bug report: https://agiv.visualstudio.com/KLIP/_sprints/taskboard/KLIP%20Team/KLIP/2024/S24.23%20-%20Wie%20Is%20Het?workitem=197067

                _getApplicationMessages();
                _initApplicationMessagePolling();
                _initSessionTimeoutPolling();
                _subscribeToSignalrEvents();
                _checkAskForImpersonation();
            }, 5000);
        });

        onBeforeUnmount(() => {
            useSignalrHub().unsubFromEvent(EEventType.AskForImpersonationEvent);
            useSignalrHub().unSubscribeFromMapRequestNotifications(useUserStore().user.klipUserId);
        });

        return {
            applicationMessages,
            isBeta,
            betaMessage,
            messages,
            whatsNew,
            openWhatsNew,
            isSessionTimeoutVisible,
            closeMessage,
            login,
            isRequestingForImpersonation,
            userImpersonation,
            consentImpersonation,
            revokeImpersonation,
        }
    }
})
