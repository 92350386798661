import { computed, defineComponent } from 'vue'

export default defineComponent({
  name: 'VlOlMapInfo',
  props: {
    tagName: {
      type: String,
      default: 'p'
    }
  },
  setup() {
    const classes = computed(() => {
      return ['vl-ol-map-info']
    })
    return {
      classes
    }
  }
})
