import {computed, defineComponent, onMounted, toRef, watch} from 'vue';
import { InvoiceDetails } from "@/app/checkout/components/invoice-details";
import { escape } from "html-escaper";
import { useUserStore } from "@/app/shared/state/UserDataModule";
import {Mri} from '@/app/mri/shared/services/services';
import {useField} from 'vee-validate';
import {klValidateRules} from '@/app/shared/components/kl-form-fields/kl-field-validation-util';

export default defineComponent({
    props: {
        invoiceDetails: {
            type: Object as () => InvoiceDetails,
            required: true,
        }
    },
    setup(props) {

        const sepaValidationRegex = /^[a-zA-Z0-9\/\-?:().,'+\s]+$/i;

        const user = useUserStore().user;
        const userEmail = computed(() => user.email);
        const isCitizen = computed((): boolean => !!user.isCitizen);
        const noVatNumberPresent = !user.organisationKboNumber;

        const vatNumberField = useField<string>(
            'BTW-nummer',
            (newValue, params) => klValidateRules({ required: noVatNumberPresent && !isCitizen.value, max: 20, vatNumberValid: true }, newValue, params));
        const emailAddressField = useField<string>('Facturatie e-mailadres', 'required|email');
        const ownReferenceField = useField<string>('Eigen referentie', { max: 25, regex: sepaValidationRegex });

        watch(() => props.invoiceDetails.vatNumber, (newValue: string) => {
            vatNumberField.value.value = newValue;
        }, { immediate: true })
        watch(() => props.invoiceDetails.emailAddress, (newValue: string) => {
            emailAddressField.value.value = newValue;
        }, { immediate: true })
        watch(() => props.invoiceDetails.ownReference, (newValue: string) => {
            ownReferenceField.value.value = newValue;
        }, { immediate: true })

        const onAddressUpdate = (newAddress: Mri.IAddress) => {
            // TEMP: VUE3-MIGRATION-FIX
            // eslint-disable-next-line vue/no-mutating-props
            props.invoiceDetails.address = newAddress;
        }

        const emailAddressAnnotation = computed(() => {
            return isCitizen.value
                ? ''
                : 'Vul hier een algemeen e-mailadres in waarop uw organisatie facturatie wenst te ontvangen.';
        });

        const ownReferenceAnnotation = computed(() => {
            return isCitizen.value
                ? ''
                : 'Optioneel kan u hier een intern bestelbon- of PO-nummer meegeven.';
        });

        const showOwnReference = computed(() => {
            return !isCitizen.value && !props.invoiceDetails.isTopupPrepaid;
        });

        const title = computed(() => {
            if (isCitizen.value) {
                return `${escape(user.firstName)} ${escape(user.lastName)}`;
            } else {
                const ovoOrKboOrOrganisationCode = user.organisationOvoCode ? `${escape(user.organisationOvoCode)}` : user.organisationKboNumber ? `${escape(user.organisationKboNumber)}` : `${escape(user.organisationCode)}`;
                return `${escape(user.organisation)} - ${ovoOrKboOrOrganisationCode}${user.organisationKboNumber ? `<br /> BTW ${escape(user.organisationVatNumber)}` : ''}`;
            }
        });


        return {
            vatNumberField,
            emailAddressField,
            ownReferenceField,

            title,
            userEmail,
            isCitizen,
            noVatNumberPresent,
            emailAddressAnnotation,
            showOwnReference,
            ownReferenceAnnotation,
            sepaValidationRegex,

            onAddressUpdate,
        }
    }
});
