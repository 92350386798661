<template>
    <vl-ol-map-search>
        <vl-input-group>
            <vl-input-addon
                tag-name="button"
                type="button"
                @click.prevent="focusSearch"
                tooltip=""
                icon="search"
                text="zoeken"
            />
            <ippro-autocomplete
                ref="autocompleteRef"
                :modelValue="searchField"
                @update:modelValue="onInput"
                :data="data"
                :fetching="fetching"
                @select="onSelect"
                placeholder="Adres, perceelnummer, coördinaat.."
                mod-block
                mod-select-first-result
            />
        </vl-input-group>
    </vl-ol-map-search>
</template>

<script lang="ts" src="./kl-map-search.ts"></script>

<style lang="scss">
@import "./kl-map-search.scss";
</style>
