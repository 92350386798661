import { defineComponent, inject, onMounted, Ref, ref, watch } from 'vue'
import iconData from './vl-ol-map-icon-data'
import Icon from 'ol/style/Icon'
import Style from 'ol/style/Style'
import Text from 'ol/style/Text'
import Fill from 'ol/style/Fill'
import Stroke from 'ol/style/Stroke'

export default defineComponent({
  name: 'VlOlMapIconStyle',
  compatConfig: {
    MODE: 3
  },
  props: {
    iconSrc: {
      type: String,
      default: iconData.src
    },
    modHighlight: Boolean,
    color: {
      type: String,
      default: 'rgba(0, 85, 204, 0.4)'
    },
    colorSelected: {
      type: String,
      default: 'rgba(0, 85, 204, 0.8)'
    },
    colorStroke: {
      type: String,
      default: '#003B8E'
    }
  },
  setup(props) {
    const icon = ref(null)
    const highlightIcon = ref(null)
    const style: Ref = inject('style')

    watch(
      () => props.iconSrc,
      () => {
        initStyle()
      },
      { immediate: true }
    )

    function initStyle() {
      if (!style.value && Style) {
        style.value = new Style()
      }
      if (Icon) {
        icon.value = new Icon({
          src: props.iconSrc
        })
        highlightIcon.value = new Icon({
          src: iconData.highlight,
          anchor: [0.5, 0.65]
        })
        style.value.setImage(props.modHighlight ? highlightIcon.value : icon.value)
      }
      style.value = addStyleFunction()
    }

    function addStyleFunction() {
      return (feature: any) => {
        const features = feature.get('features')
        const size = features?.length || 0
        feature = features ? features[0] : feature
        const selected = feature.get('selected')
        const geometryStyle = new Style({
          fill: new Fill({ color: selected ? props.colorSelected : props.color }),
          stroke: new Stroke({ color: props.colorStroke, width: selected ? 4 : 1 }),
          geometry: feature.getGeometry()
        })
        let src
        if (size === 1) {
          return [
            new Style({
              image: selected ? highlightIcon.value : icon.value,
              geometry: feature.getGeometry()
            }),
            geometryStyle
          ]
        }
        if (size > 1) {
          if (size < 10) {
            src = iconData.clusterIcon.s
          } else if (size < 100) {
            src = iconData.clusterIcon.m
          } else if (size < 1000) {
            src = iconData.clusterIcon.l
          } else {
            src = iconData.clusterIcon.xl
          }

          return new Style({
            image: new Icon({
              src
            }),
            text: new Text({
              font: '16px Flanders Art Sans',
              fill: new Fill({ color: '#FFF' }),
              text: `${size}`
            })
          })
        }

        return [style.value, geometryStyle]
      }
    }
  }
})
