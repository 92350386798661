<kl-sidebar>
    <template v-if="forGeometry">
        <kl-draw-zone-sidebar-geometry-info
            v-if="listZones && currentZone"
            :geometry-info="currentZone?.geometryInfo"
            :show-remove="showRemoveGeometry"
            @remove-geometry="onRemoveGeometry"
        />
        <kl-draw-zone-sidebar-geometry-actions
            v-if="listZones && !currentZone"
            @goto-create="create"
            @goto-import="zoneImport"
        />
        <!-- TODO: juiste file upload restricties doorgeven! -->
        <kl-draw-zone-sidebar-import
            v-if="importZone"
            title="Uw zone"
            :for-maprequest="false"
            :for-geometry="true"
            :mod-enable-multi-zones="modEnableMultiZones"
            :has-import-errors="hasImportErrors"
            :has-import-warnings="hasImportWarnings"
            :import-errors="importErrors"
            :import-more-info-link="importMoreInfoLink"
            :max-files-to-import="1"
            @cancel="onCancelImport"
            @files-uploaded="onFilesUploaded"
        />
        <kl-draw-zone-sidebar-geometry-create-edit
            v-if="createZone || editZone"
            title="Uw zone"
            :is-edit="editZone"
            @remove-geometry="removeCurrentZone"
        />
    </template>

    <template v-else-if="forKlbZones">
        <kl-draw-zone-sidebar-klb-list
            :sidebar-title="sidebarTitle"
            :can-add-klb-zone="canAddKlbZone"
            :can-add-klim-zone="canAddKlimZone"
            :zones="zones"
            :show-klb-archived-zone-toggle="showKlbArchivedZoneToggle"
            :klb-archived-zone-toggle-value="klbArchivedZoneToggleValue"
            @add-new-klb-zone="addNewKlbZone"
            @add-new-klim-zone="addNewKlimZone"
            @edit-klb-zone="editKlbZone"
            @highlight-klb-zone="onKlbZoneHover"
            @toggle-show-active="onKlbToggleShowActive"
        />
    </template>

    <!-- create || edit zone -->
    <template v-else-if="editZone || createZone">

        <kl-draw-zone-sidebar-header :title="sidebarTitle" tag-style="h5"/>
        <kl-draw-zone-sidebar-header title="Teken zone" tag-style="h6"/>

        <vl-sidebar-content v-if="createZone"
                            class="kl-sidebar__section kl-sidebar__section-larger kl-sidebar__section-reduced-separator">
            <p class="kl-sidebar__section__fillrow" v-html="sidebarText"></p>
        </vl-sidebar-content>

        <!-- EDIT: editZone -->
        <template v-if="editZone">
            <vl-sidebar-content class="kl-sidebar__section kl-sidebar__section-smaller" v-if="!editZoneName">
                <div class="kl-sidebar__section__fillrow kl-sidebar__section__fillrow__clip">
                    <strong>{{ zoneDetailTitle }}</strong></div>
                <div class="kl-sidebar__section__vertical-separator-smaller" v-if="canEditZoneName"></div>
                <vl-button icon="pencil" mod-icon mod-naked-action mod-large @click="onEditZoneName"
                           class="kl-sidebar__section__action" v-if="canEditZoneName"></vl-button>
            </vl-sidebar-content>
            <vl-sidebar-content
                class="kl-sidebar__section kl-sidebar__section-smaller kl-sidebar__section__edit-zone-name"
                v-if="editZone && editZoneName">
                <vl-input-group class="kl-sidebar__section__fillrow">
                    <vl-input-field name="referentie" v-model="currentZoneName" mod-block @input="onInputZoneName"/>
                    <vl-button icon="check-small" mod-icon mod-narrow @click="onStopEditZoneName"
                               :mod-disabled="editZoneNameHasValidationError"/>
                </vl-input-group>
                <div class="kl-sidebar__section__edit-zone-name__col-error" v-if="editZoneNameHasValidationError">
                    <span v-vl-text.bold.small.error>Uw referentie mag niet groter zijn dan 100 karakters</span>
                </div>
            </vl-sidebar-content>
            <vl-sidebar-content class="kl-sidebar__section kl-sidebar__section-reduced-separator"
                                v-if="zoneDetailDescription">
                <p class="kl-sidebar__section__fillrow">{{ zoneDetailDescription }}</p>
            </vl-sidebar-content>
        </template>

        <!-- CREATE && EDIT -->
        <vl-sidebar-content class="kl-sidebar__section">
            <div class="kl-sidebar__section__edit-zone-detail">
                <div class="kl-sidebar__section__edit-zone-detail__col1">
                    <strong class="kl-sidebar__text13">OPPERVLAKTE</strong>
                    <div v-html="currentPolygonFeatureAreaFormatted"></div>
                </div>
                <div class="kl-sidebar__section__vertical-separator"></div>
                <div class="kl-sidebar__section__edit-zone-detail__col2" v-if="!currentZoneError">
                    <span v-html="currentZoneDetailInfo" v-if="currentZoneDetailInfo"></span>
                </div>
                <div class="kl-sidebar__section__vertical-separator" v-if="!currentZoneError && currentZonePrice"></div>
                <div class="kl-sidebar__section__edit-zone-detail__col3" v-if="!currentZoneError && currentZonePrice">
                    <strong><span class="kl-sidebar__text13">€</span>&nbsp;<span>{{ currentZonePrice }}</span></strong>
                </div>
                <!-- ERROR -->
                <div class="kl-sidebar__section__edit-zone-detail__col-error" v-if="currentZoneError">
                    <vl-icon icon="alert-triangle-filled" mod-large/>
                    <span v-vl-text.bold.small.error>{{ currentZoneError }}</span>
                </div>
            </div>
        </vl-sidebar-content>

        <kl-draw-zone-sidebar-filler/>

        <!-- EDIT -->
        <vl-sidebar-footer class="kl-sidebar__section kl-sidebar__section-no-border-bottom"
                           v-if="isConfirmVisible || isRemoveVisible">
            <vl-action-group class="kl-sidebar__section__actions">
                <vl-button ref="confirmZone" v-if="isConfirmVisible" @click.prevent="confirm" :mod-disabled="!editZone" mod-block mod-small>
                    Bevestig zone
                </vl-button>
                <vl-button v-if="isRemoveVisible" ref="removeZone" @click.prevent="removeCurrentZone" mod-secondary
                           mod-error mod-small class="kl-sidebar__section__actions__button-smaller">
                    Verwijder
                </vl-button>
                <vl-button ref="revertZone" v-if="isConfirmVisible" @click.prevent="revertCurrentZone" mod-tertiary
                           mod-small mod-naked-action class="kl-sidebar__section__actions__button-smaller">
                    Annuleren
                </vl-button>
            </vl-action-group>
        </vl-sidebar-footer>
    </template>

    <!-- import zone -->
    <template v-else-if="importZone" class="kl-sidebar__import-container">
        <kl-draw-zone-sidebar-import
            :title="sidebarTitle"
            :for-maprequest="forMaprequest"
            :mod-enable-multi-zones="modEnableMultiZones"
            :has-import-errors="hasImportErrors"
            :has-import-warnings="hasImportWarnings"
            :import-errors="importErrors"
            :import-more-info-link="importMoreInfoLink"
            :max-files-to-import="maxFilesToImport"
            @cancel="onCancelImport"
            @files-uploaded="onFilesUploaded"
        />
    </template>

    <!-- list zones -->
    <template v-else-if="listZones">
        <kl-draw-zone-sidebar-header :title="sidebarTitle" tag-style="h5"/>
        <vl-sidebar-content v-if="canAddMoreZones" class="kl-sidebar__section" ref="actions">
            <vl-action-group class="kl-sidebar__section__actions">
                <vl-button @click.prevent="create" ref="drawButton" mod-small mod-block>
                    Teken zone
                </vl-button>
                <vl-button @click.prevent="zoneImport" ref="importButton" mod-small mod-secondary mod-block>
                    {{ importButtonLabel }}
                </vl-button>
            </vl-action-group>
        </vl-sidebar-content>

        <vl-sidebar-content mod-container v-if="hasImportWarnings" class="kl-sidebar__section-warning">
            <div class="kl-sidebar__warning">
                <vl-title tag-style="h6" class="kl-sidebar__text18 kl-sidebar__margin-bottom5">Let op!</vl-title>
                <vl-button class="kl-sidebar__warning__close" icon="close" mod-icon mod-naked-action
                           @click="onCloseWarnings"></vl-button>
                <div class="kl-sidebar__margin-top5">
                    <div class="kl-sidebar__text18 kl-sidebar__margin-bottom5" v-for="(warning) in importWarnings">
                        {{ warning }}
                    </div>
                </div>
            </div>
        </vl-sidebar-content>

        <kl-draw-zone-sidebar-filler v-if="!hasZones"/>

        <vl-sidebar-content mod-container v-if="hasZones">
            <perfect-scrollbar
                :options="{ suppressScrollX: true, suppressScrollY: false, wheelPropagation: false }"
                class="kl-sidebar__scroll-area"
            >
                <ul class="kl-sidebar__list">
                    <li class="kl-sidebar__section" v-for="(cz) in zones">
                        <vl-button mod-naked-action @click.prevent="edit(cz.id)"
                                   class="kl-sidebar__section__zone-summary">
                            <vl-link href="#" mod-bold>
                                <strong>{{ cz.name }}</strong>
                            </vl-link>
                            <div class="kl-sidebar__section__zone-summary__sub" v-if="getZoneListInfo(cz)"
                                 v-html="getZoneListInfo(cz)" v-vl-text.small></div>
                        </vl-button>
                        <div class="kl-sidebar__section__vertical-separator"></div>
                        <span v-if="cz.price" class="kl-sidebar__section__zone-price">
                            <strong><span class="kl-sidebar__text13">€</span>&nbsp;<span>{{ cz.price }}</span></strong>
                        </span>
                        <vl-button v-if="!cz.price" class="kl-sidebar__section__zone-remove" icon="bin" mod-icon
                                   mod-naked-action mod-error mod-large @click="remove(cz)"></vl-button>
                    </li>

                    <!-- IMKL -->
                    <li class="kl-sidebar__section" v-if="getTotalImklPrice()">
                        <div class="kl-sidebar__section__totaal">
                            <strong>{{ getImklDescription() }}</strong>
                        </div>
                        <div class="kl-sidebar__section__vertical-separator"></div>
                        <div class="kl-sidebar__section__zone-price">
                            <strong><span class="kl-sidebar__text13">€</span>&nbsp;<span>{{getTotalImklPrice()}}</span></strong>
                        </div>
                    </li>
                </ul>
            </perfect-scrollbar>
        </vl-sidebar-content>

        <vl-sidebar-footer class="kl-sidebar__section kl-sidebar__section-no-border-bottom"
                           v-if="modMaprequestZone && getTotalPrice()" ref="total">
            <div class="kl-sidebar__section__totaal vl-title vl-title--h5">
                <strong>TOTAAL <span v-if="applyVat" v-vl-text.small>excl. BTW</span></strong>
            </div>
            <div class="kl-sidebar__section__vertical-separator"></div>
            <div class="kl-sidebar__section__zone-price vl-title vl-title--h5">
                <span class="kl-sidebar__text16">€</span>&nbsp;<span>{{ getTotalPrice() }}</span>
            </div>
        </vl-sidebar-footer>
    </template>
</kl-sidebar>
