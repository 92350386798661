<ippro-map ref="map" :is-loading="isLoading || modDisabled" :loading-message="loadingMessage"
           :mod-layers="['grb_bsk', 'omwrgbmrvl']" mod-toggle-fullscreen>
    <template v-slot:sidebar>
        <kl-draw-zone-sidebar
            name="sidebar"
            ref="sidebar"
            :mod-disabled="modDisabled"
            :no-confirmation="noConfirmation"
            :rules="rules"
            :reference="reference"
            :include-imkl="includeImkl"
            :for-maprequest="forMaprequest"
            :mod-maprequest-zone="modMaprequestZone"
            :mod-enable-multi-zones="modEnableMultiZones"
            @state="onStateChange"
            @zoom-to-feature="onZoomToFeature"
            @zoom-to-features="onZoomToFeatures"
            @loading="onLoading"
            @input="onInput"
            @zone-price="onZonePrice"

            :for-klb-zones="forKlbZones"
            :can-add-klb-zone="canAddKlbZone"
            :can-add-klim-zone="canAddKlimZone"
            :add-klb-zone-link="addKlbZoneLink"
            :add-klim-zone-link="addKlimZoneLink"
            @toggle-show-active="onKlbToggleShowActive"
            :show-klb-archived-zone-toggle="showKlbArchivedZoneToggle"
            :klb-archived-zone-toggle-value="klbArchivedZoneToggleValue"

            :allow-multi-polygons="allowMultiPolygons"

            :for-geometry="forGeometry"
            :show-remove-geometry="showRemoveGeometry"
            @remove-geometry="onRemoveGeometry"
        />
    </template>

    <template v-slot:search>
        <kl-map-search @extent-selected="onSearchOnExtent" @coordinate-selected="onSearchOnCoordinate"/>
    </template>

    <template v-if="modShowKmHmToggle">
        <div class="kmhm-container">
            <div class="kmhm-container__background">
                <vl-checkbox @update:modelValue="toggleKmHmLayer" :modelValue="kmhmEnabled">
                    km- en hm-punten
                    <vl-loader mod-inline mod-small class="kmhm-container__loader" v-if="kmhmEnabled && kmhmIsLoading"/>
                    <vl-icon icon="alert-triangle-filled" class="kmhm-container__error"
                             v-if="kmhmEnabled && kmhmHasError"/>
                </vl-checkbox>
            </div>
        </div>
    </template>
</ippro-map>
