import KlCreateGeometryOnMap from './components/kl-create-geometry-on-map/kl-create-geometry-on-map.vue';
import { IGeometryExtended} from './components/kl-create-geometry-on-map/kl-create-geometry-on-map';
import { IFlatGeometry } from './components/kl-create-geometry-on-map/kl-create-geometry-on-map';
import {useSettingsStore} from '@/app/settings/settings-store';
import {Geometry, UpdateKlipZoneInput, CreateKlipZoneInput, GeometryInput, IGeometryInput} from '@/api/klip-api.proxy';
import { EditSubOrganisation } from '../../../../kl-organisation-routes';
import {computed, defineComponent, onMounted, ref, watch} from 'vue';
import {useRouter} from '@/router/router-vue3';
import {useKlipApiProxy} from '@/plugins/proxy-client';
import {componentRef} from '@/app/shared/helpers/vue-typescript-util';
import {useField, useForm} from 'vee-validate';
import {klValidateRules} from '@/app/shared/components/kl-form-fields/kl-field-validation-util';
import {useKlModalEventsUtil} from '@/app/shared/helpers/kl-modal-events-util';
import DateUtil from '@/app/shared/helpers/date-util';
import {first} from 'lodash-es';

export default defineComponent({
    name: 'KlUnaCreateEditZone',
    components: {
        KlCreateGeometryOnMap,
    },
    props: {
        zoneId: { type: String, default: '' },
        organisationId: { type: String, default: '' },
    },
    setup(props) {

        const createGeometryOnMap = ref<InstanceType<typeof KlCreateGeometryOnMap>>(null);

        const router = useRouter();

        const removeZoneModalEvents = useKlModalEventsUtil('remove-zone');
        const removeGeometryModalEvents = useKlModalEventsUtil('remove-geometry');


        const namespaceUrl: string = import.meta.env.VITE_LINKS_NAMESPACE;
        const overlayUrl: string = import.meta.env.VITE_LINKS_OVERLAY;
        const helpdeskMail: string = import.meta.env.VITE_EMAIL_HELPDESK;
        const zoneNameValidationRegex = /^[a-zA-Z0-9\-_ .+!()&ö/:,è|'@\s]*$/;
        const namespaceRegex = /^[0-9a-zA-Z-_]{5,50}$/;

        const hasValidationErrors = ref<boolean>(false);
        const hasGeometryValidationErrors = ref<boolean>(false);


        const form = useForm();
        const disableSubmit = computed((): boolean => {
            const invalid = !form.meta.value.valid;
            const pristine =!form.meta.value.dirty;

            // make sure the method update is triggered when these refs change
            hasGeometry.value;
            geometriesTouched.value;

            // never disable the submit in createMode
            if (!editMode.value) {
                return false;
            }

            // check invalid
            if (invalid || !hasGeometry.value) {
                // console.log('invalid || !hasGeometry.value', invalid || !hasGeometry.value)
                return true;
            }
            // check if there are changes
            if (pristine && !geometriesTouched.value) {
                // console.log('pristine && !geometriesTouched.value', pristine && !geometriesTouched.value)
                return true;
            }
            return false;
        });

        const initialName = ref<string>('');

        const nameField = useField<string>(
            'Naam zone',
            (newValue, params) => klValidateRules({ 'required': true, 'max': 90, 'regex': zoneNameValidationRegex, 'zoneNameIsUnique': [props.zoneId, initialName.value]}, newValue, params));
        const mailDestinationField = useField<string>('Stuur planaanvragen naar e-mail', 'required|validKlipEmail');
        const mailSettingsField = useField('mailSettings');
        const namespaceField = useField<string>(
            'Namespace',
            (newValue, params) => klValidateRules({ 'required': true, 'regex': namespaceRegex, 'uniqueNamespace': [props.zoneId] }, newValue, params));
        const contactNameField = useField<string>('Naam contact', 'required|max:100');
        const contactPhoneField = useField<string>('Telefoon', 'required|phone');
        const contactEmailField = useField<string>('Email', 'required|validKlipEmail');
        const contactMessageField = useField<string>('Boodschap voor de planaanvrager', 'max:400');
        const contactPhoneEmergencyField = useField<string>('Telefoon in geval van nood', 'required|max:20|phone_with_112');

        const name = nameField.value;
        const mailDestination = mailDestinationField.value;
        const namespace = namespaceField.value;
        const contact = ref({
            name: '',
            phone: '',
            email: '',
            message: '',
            phoneEmergency: '',
        });

        watch(() => contact.value.name, (newValue: string) => { contactNameField.value.value = newValue; });
        watch(() => contact.value.phone, (newValue: string) => { contactPhoneField.value.value = newValue; });
        watch(() => contact.value.email, (newValue: string) => { contactEmailField.value.value = newValue; });
        watch(() => contact.value.message, (newValue: string) => { contactMessageField.value.value = newValue; });
        watch(() => contact.value.phoneEmergency, (newValue: string) => { contactPhoneEmergencyField.value.value = newValue; });

        const mailSettings = ref({
            confirmation: false,
            confirmationExampleUri: '',
            reminder1DayToRespond: false,
            reminder1DayToRespondExampleUri: '',
            uploadImklSuccess: false,
            uploadImklSuccessExampleUri: '',
            uploadImklFailed: false,
            uploadImklFailedExampleUri: '',
            respondedNotInvolved: false,
            respondedNotInvolvedExampleUri: ''
        });

        // update form.meta.value.dirty flag
        watch(mailSettings, (newValue) => { mailSettingsField.setValue (newValue); }, { deep: true });


        const isLoading = ref<boolean>(true);

        const isSending = ref<boolean>(false);
        const deletingZone = ref<boolean>(false);
        const deletingGeometry = ref<boolean>(false);
        const addingNewGeometry = ref<boolean>(false);
        const processingGeometry = ref<boolean>(false); // only for old: !useDrawZoneComponent
        const geometry = ref<IFlatGeometry>(null);
        const geometries = ref<IGeometryExtended[]>([]);
        const geometryToBeDeleted = ref<Geometry>();
        const unaDisplayName = ref<string>('');
        const unaId = ref<number>();
        const geometriesTouched = ref<boolean>(false);

        const _deleteNamespaceZone = (deleteNamespace: { id: string, organisationId: string, unaId: number }) => useSettingsStore().deleteNamespaceZone(deleteNamespace);

        const routeEditOrganinsation = computed(() => {
            return EditSubOrganisation;
        });

        const editMode = computed((): boolean => {
            return !!props.zoneId;
        });

        const createMode = computed((): boolean => {
            return !props.zoneId;
        });

        const emptyGeometry = computed((): boolean => {
            return !!geometries.value && geometries.value.filter((g) => g.isDeleted === false).length === 0;
        });

        const canAddGeometry = computed((): boolean => {
            return editMode.value && !addingNewGeometry.value;
        });

        const hasGeometry = computed(() => {
            if (!editMode.value) {
                return !!geometry.value;
            } else {
                const numberOfGeometries = geometries.value.filter((g) => g.isDeleted === false).length;
                return numberOfGeometries > 0;
            }
        });

        const canRevert = computed((): boolean => {
            return !!geometries.value.length;
        });

        const title = computed((): string => {
            return editMode.value ? 'Bewerk zone' : 'Nieuwe zone';
        });

        const submitButtonLabel = computed((): string => {
            return editMode.value ? 'Bewaar veranderingen' : 'Zone toevoegen';
        });

        const geometryUpdate = (newGeometry: IFlatGeometry) => {
            geometry.value = newGeometry;
            processingGeometry.value = true;
        }

        const geometryUploadFinished = (newGeometry: IGeometryExtended) => {
            geometries.value.push(newGeometry);
            addingNewGeometry.value = false;
            processingGeometry.value = false;
            _markGeometriesTouched();
        }

        const geometryBeforeDelete = (newGeometry: Geometry) => {
            geometryToBeDeleted.value = newGeometry;
            removeGeometryModalEvents.triggerModalToggle();
        }

        const geometryDelete = () => {
            deletingGeometry.value = true;

            // create mode = only 1 geometry possible
            if (!editMode.value) {
                geometry.value = null;
            }

            // edit mode
            const index = geometries.value.findIndex((g) => g.id === geometryToBeDeleted.value.id);
            if (index > -1) {
                geometries.value.splice(index, 1);
            }
            _markGeometriesTouched();

            deletingGeometry.value = false;
        }

        const _markGeometriesTouched = () => {
            geometriesTouched.value = true;
        }

        const _submit = async () => {

            isSending.value = true;

            hasValidationErrors.value = false;
            hasGeometryValidationErrors.value = false;

            const createOnMap = componentRef<InstanceType<typeof KlCreateGeometryOnMap>>(createGeometryOnMap).value;

            // create-flow: auto-submit geometry
            if (!editMode.value && !!createOnMap) {
                const validateGeometryResult = await createOnMap.onSubmit();
                if (!validateGeometryResult) {
                    hasGeometryValidationErrors.value = true;
                    isSending.value = false;
                    return;
                }
            }

            // Double check (should not be possible)
            if (!hasGeometry.value) {
                hasGeometryValidationErrors.value = true;
                isSending.value = false;
                return;
            }

            const data = {
                organisationId: props.organisationId,
                alarmCentralTelephone: contact.value.phoneEmergency,
                contactName: contact.value.name,
                contactEmail: contact.value.email,
                contactTelephone: contact.value.phone,
                extraInformation: contact.value.message,
                mapRequestEmail: mailDestination.value,
                name: name.value,
                namespace: namespace.value,
                confirmation: mailSettings.value.confirmation,
                reminder1DayToRespond: mailSettings.value.reminder1DayToRespond,
                respondedNotInvolved: mailSettings.value.respondedNotInvolved,
                uploadImklFailed: mailSettings.value.uploadImklFailed,
                uploadImklSuccess: mailSettings.value.uploadImklSuccess
            } as UpdateKlipZoneInput | CreateKlipZoneInput;

            if (editMode.value) {
                const fullData = _addGeometriesToUpdateKlipZone(data);
                await useSettingsStore().updateKlipNamespaceZone(fullData);
            }
            else {
                const fullData = _addGeometriesToCreateKlipZone(data);
                await useSettingsStore().createKlipNamespaceZone(fullData);
            }

            isSending.value = false;
            addingNewGeometry.value = false;

            await router.replace({
                name: routeEditOrganinsation.value.name,
                params: {
                    organisationId: props.organisationId,
                }
            });
        }

        const cancelNewGeometry = () => {
            addingNewGeometry.value = false;
        }

        const addNewGeometry = () => {
            addingNewGeometry.value = true;
        }

        const deleteZone = () => {
            deletingZone.value = true;

            _deleteNamespaceZone({ id: props.zoneId, organisationId: props.organisationId, unaId: unaId.value }).then(async () => {
                removeZoneModalEvents.triggerModalToggle();
                await router.replace({
                    name: routeEditOrganinsation.value.name,
                    params: {
                        organisationId: props.organisationId,
                        removedItem: props.zoneId
                    },
                });
            });
        }

        const _addGeometriesToCreateKlipZone = (createZoneInput: CreateKlipZoneInput) => {
            const klipData = CreateKlipZoneInput.fromJS({
                ...createZoneInput,
                geometryActivationDate: DateUtil.formatForApi(first(geometry.value.activation.geometryActivationDate), 'YYYY-MM-DD 00:00:00'),
                geometryWkt: geometry.value.file.geometryWkt,
                geometryLastModificationDate: DateUtil.formatForApi(first(geometry.value.reference.geometryLastModificationDate), 'YYYY-MM-DD 00:00:00'),
                geometryPrecision: geometry.value.reference.geometryPrecision,
                geometryReference: geometry.value.reference.geometryReference,
                geometryVersion: geometry.value.reference.geometryVersion,
            });
            return klipData;
        }

        const _addGeometriesToUpdateKlipZone = (updateZoneInput: UpdateKlipZoneInput): UpdateKlipZoneInput => {
            const klipData = UpdateKlipZoneInput.fromJS({
                ...updateZoneInput,
                id: props.zoneId,
                geometries: geometries.value
                    .filter((g) => g.isDeleted === false)
                    .map((geometry: IGeometryExtended): GeometryInput => {
                        return GeometryInput.fromJS({
                            activationDate: DateUtil.formatForApi(geometry.activationDate, 'YYYY-MM-DD 00:00:00'),
                            id: geometry.id,
                            wkt: geometry.wkt,
                            isActive: geometry.isActive,
                            lastModificationDate: DateUtil.formatForApi(geometry.lastModificationDate, 'YYYY-MM-DD 00:00:00'),
                            precision: geometry.precision,
                            reference: geometry.reference,
                            version: geometry.version,
                        });
                    }),
                });
            return klipData;
        }

        onMounted(() => {
            addingNewGeometry.value = !!createMode.value;
            processingGeometry.value = false;
        });

        const _onZoneIdChange = (newZoneId: string) => {
            if (!!newZoneId) {
                isLoading.value = true;
                useKlipApiProxy().unaSettings_GetUnaZoneEditView(newZoneId, props.organisationId)
                    .then((response) => {
                        initialName.value = response.result.zoneName;
                        name.value = response.result.zoneName;
                        unaDisplayName.value = response.result.unaName;
                        mailDestination.value = response.result.mapRequestEmail;
                        namespace.value = response.result.namespace;
                        geometries.value = response.result.geometries;
                        unaId.value = response.result.unaId;
                        contact.value = {
                            name: response.result.contactName,
                            phone: response.result.contactTelephone,
                            email: response.result.contactEmail,
                            message: response.result.extraInformation,
                            phoneEmergency: response.result.alarmCentralTelephone,
                        };
                        mailSettings.value = {
                            confirmation: response.result.confirmation,
                            confirmationExampleUri: response.result.confirmationExampleUri,
                            reminder1DayToRespond: response.result.reminder1DayToRespond,
                            reminder1DayToRespondExampleUri: response.result.reminder1DayToRespondExampleUri,
                            uploadImklFailed: response.result.uploadImklFailed,
                            uploadImklFailedExampleUri: response.result.uploadImklFailedExampleUri,
                            uploadImklSuccess: response.result.uploadImklSuccess,
                            uploadImklSuccessExampleUri: response.result.uploadImklSuccessExampleUri,
                            respondedNotInvolved: response.result.respondedNotInvolved,
                            respondedNotInvolvedExampleUri: response.result.respondedNotInvolvedExampleUri
                        };
                        geometries.value.forEach((g) => g.isDeleted = false);
                        isLoading.value = false;
                    });
            } else {
                isLoading.value = true;
                useKlipApiProxy().unaSettings_GetUnaZoneCreateView()
                    .then((response) => {
                        mailSettings.value = {
                            confirmation: true,
                            confirmationExampleUri: response.result.confirmationExampleUri,
                            reminder1DayToRespond: false,
                            reminder1DayToRespondExampleUri: response.result.reminder1DayToRespondExampleUri,
                            uploadImklFailed: false,
                            uploadImklFailedExampleUri: response.result.uploadImklFailedExampleUri,
                            uploadImklSuccess: false,
                            uploadImklSuccessExampleUri: response.result.uploadImklSuccessExampleUri,
                            respondedNotInvolved: false,
                            respondedNotInvolvedExampleUri: response.result.respondedNotInvolvedExampleUri
                        };
                        isLoading.value = false;
                    });
            }
        }

        watch(
            geometries,
            (newGeometries: IGeometryExtended[]) => {
                if (!newGeometries.length) {
                    addingNewGeometry.value = true;
                }
            },
            { immediate: false, deep: true });

        watch(
            () => props.zoneId,
            _onZoneIdChange,
            { immediate: true });


        const _onInvalidSubmit = (submittedValues) => {
            hasValidationErrors.value = true;
        }

        const onSubmit = form.handleSubmit(_submit, _onInvalidSubmit);

        return {
            nameField,
            mailDestinationField,
            namespaceField,
            contactNameField,
            contactPhoneField,
            contactEmailField,
            contactMessageField,
            contactPhoneEmergencyField,

            namespaceUrl,
            overlayUrl,
            helpdeskMail,
            zoneNameValidationRegex,
            namespaceRegex,

            createGeometryOnMap,

            isLoading,
            name,
            initialName,
            mailDestination,
            namespace,
            contact,
            mailSettings,
            isSending,
            deletingZone,
            deletingGeometry,
            addingNewGeometry,
            geometry,
            geometries,
            geometryToBeDeleted,
            unaDisplayName,
            unaId,

            geometriesTouched,

            routeEditOrganinsation,
            editMode,
            createMode,
            emptyGeometry,
            canAddGeometry,
            canRevert,
            title,
            submitButtonLabel,

            hasGeometry,
            hasValidationErrors,
            hasGeometryValidationErrors,

            geometryUpdate,
            geometryUploadFinished,
            geometryBeforeDelete,
            geometryDelete,
            cancelNewGeometry,
            addNewGeometry,
            deleteZone,

            onSubmit,
            disableSubmit,
        }

    }
})
