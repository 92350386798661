<vl-ol-map-tools-action
    v-show="!active"
    @click.prevent="toggleFullscreen(true)"
    icon="expand"
    text="Volledig&nbsp;scherm"
    v-vl-tooltip.left.hide-immediately="'Volledig&nbsp;scherm'"
/>
<vl-ol-map-tools-action
    v-show="active"
    @click.prevent="toggleFullscreen(false)"
    icon="shrink"
    text="Sluit&nbsp;volledig&nbsp;scherm"
    v-vl-tooltip.left.hide-immediately="'Sluit&nbsp;volledig&nbsp;scherm'"
/>
