import {App, Plugin} from 'vue';
import Multiselect from 'vue-multiselect';
import VueJsonPretty from 'vue-json-pretty';
import { PerfectScrollbarPlugin } from 'vue3-perfect-scrollbar';
import 'vue3-perfect-scrollbar/style.css';
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc"
import customParseFormat from 'dayjs/plugin/customParseFormat';
import 'dayjs/locale/nl-be';


export default {
    install(app: App) {
        app.component("vue-json-pretty", VueJsonPretty)
        app.component('multiselect', Multiselect);

        app.use(PerfectScrollbarPlugin);

        // DayJs
        dayjs.extend(utc);
        dayjs.extend(customParseFormat);
        dayjs.locale('nl-be');
    }
} as Plugin;
