import { computed, defineComponent } from 'vue'

export default defineComponent({
  name: 'VlOlMapSearch',
  props: {
    tagName: {
      type: String,
      default: 'div'
    },
    modOffset: Boolean
  },
  setup(props) {
    const classes = computed(() => {
      return [
        'vl-ol-map-search',
        {
          'vl-ol-map-search--offset': props.modOffset
        }
      ]
    })
    return {
      classes
    }
  }
})
