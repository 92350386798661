<div v-vl-align:center v-if="viewModelLoading">
    <vl-loader message="De gegevens worden geladen. Even geduld, a.u.b." />
</div>


<div v-else>
    <template v-if="isKnownInKlip && !isLegalRepresentative">
        <vl-typography>
            U heeft een geldig rijksregister- of BIS-nummer: <br />
            Op basis van uw antwoorden van daarnet, moet u nog de gewenste rechten aanvragen bij uw lokaal beheerder via onderstaande link(s):        
        </vl-typography>
        <vl-u-spacer mod-small/>
        <vl-typography>
            <ul>
                <li v-if="hasRequestedRole('mrr') && !hasRequestedRole('mri')">Plannen van uw organisatie bekijken: <a :href="mrrRegistrationLink">KLIP Raadpleger</a> </li>
                <li v-if="hasRequestedRole('mri')">Plannen voor uw organisatie aanvragen en raadplegen: <a :href="mriRegistrationLink">KLIP Planaanvrager</a></li>
                <li v-if="hasRequestedRole('una')">Uw zones als kabel- en leidingbeheerder beheren: <a :href="unaAdminRegistrationLink">KLIP KLB Beheerder</a></li>
                <li v-if="hasRequestedRole('una')">Planaanvragen beantwoorden: <a :href="unaOperatorRegistrationLink">KLIP KLB Operator</a></li>
                <li v-if="hasRequestedRole('una')">Enkel antwoorden kunnen raadplegen: <a :href="unaReaderRegistrationLink">KLIP KLB Raadpleger</a></li>
                <li v-if="hasRequestedRole('pda')">Planaanvragen (beperkte inhoud) en leidingbeheerders op uw openbaar domein raadplegen: <a :href="odbRegistrationLink">KLIP ODB Beheerder</a></li>
            </ul>
        </vl-typography>
        <vl-u-spacer mod-small/>
        <vl-typography>
            Geen geldig rijksregister- of BIS-nummer? <vl-link href="https://www.vlaanderen.be/informatie-en-communicatie-van-de-vlaamse-overheid/over-1700">Contacteer 1700</vl-link>
        </vl-typography>
    </template>
    <template v-else>
        <vl-typography v-if="!isKnownInKlip && !isLegalRepresentative">
            De opgegeven organisatie is niet gekend in KLIP.
        </vl-typography>
        <vl-typography v-if="(hasRequestedRole('mrr') || hasRequestedRole('mri')) && isLegalRepresentative">
            Wil je plannen van uw organisatie bekijken: Heeft u een geldig rijksregister- of BIS-nummer? U hoeft zich niet te registreren:
            <vl-link href="auth/login">Meld u onmiddellijk aan op KLIP.</vl-link>
            <vl-u-spacer mod-small/>
            Geen geldig rijksregister- of BIS-nummer? <vl-link href="https://www.vlaanderen.be/informatie-en-communicatie-van-de-vlaamse-overheid/over-1700">Contacteer 1700</vl-link>
            <vl-u-spacer mod-small/>
            Uw werknemers toegang geven tot KLIP? Stel dit in via <vl-link :href="linkGebruikersBeheerVlaanderen" target="_blank">Gebruikersbeheer Vlaanderen</vl-link>.
        </vl-typography>
        <vl-typography v-if="(hasRequestedRole('mrr') || hasRequestedRole('mri')) && !isLegalRepresentative && !isKnownInKlip">
            <template v-if="hasRequestedRole('mrr') && !hasRequestedRole('mri')">
                Wil je plannen van uw organisatie enkel kunnen bekijken: Neem contact op met het gratis informatienummer van de Vlaamse overheid <vl-link href="https://www.vlaanderen.be/informatie-en-communicatie-van-de-vlaamse-overheid/over-1700">1700</vl-link>
            </template>
            <template v-if="hasRequestedRole('mri')">
                Wil je plannen van uw organisatie kunnen aanvragen en raadplegen: Neem contact op met het gratis informatienummer van de Vlaamse overheid <vl-link href="https://www.vlaanderen.be/informatie-en-communicatie-van-de-vlaamse-overheid/over-1700">1700</vl-link>
            </template>
        </vl-typography>
        <vl-u-spacer mod-small/>
        <vl-typography v-if="hasRequestedRole('pda')">
            Wil je plannen van uw organisatie bekijken of Planaanvragen (beperkte inhoud) en leidingbeheerders op uw openbaar domein raadplegen: Neem contact op met het gratis informatienummer van de Vlaamse overheid <vl-link href="https://www.vlaanderen.be/informatie-en-communicatie-van-de-vlaamse-overheid/over-1700">1700</vl-link>
        </vl-typography>
        <vl-u-spacer mod-small/>
        <vl-typography v-if="hasRequestedRole('una')">
            Wil je planaanvragen beantwoorden, enkel antwoorden raadplagen of uw zones als kabel- en leidingbeheerder beheren: Contacteer een KLIP-medewerker via <vl-link href="mailto:klip@athumi.eu">klip@athumi.eu</vl-link>.
        </vl-typography>
    </template>
</div>