<template>
<vl-sidebar-content class="kl-sidebar__empty-filler"></vl-sidebar-content>
</template>

<script lang="ts">

import {defineComponent} from 'vue';

export default defineComponent({
})

</script>

