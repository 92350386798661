<template>
    <component :is="tagName" :class="classes">
        <button v-if="!modToggle" class="vl-ol-map-base-layer__more" @click="toggleBaseLayer">
            <vl-icon class="vl-ol-map-base-layer__more__icon" icon="nav-left" />
        </button>
        <slot/>
    </component>
</template>

<script src="./vl-ol-map-base-layer.ts"></script>
