import { computed, defineComponent, onMounted, ref, watch } from 'vue'

export default defineComponent({
  name: 'VlOlMapToolsActionGroup',
  props: {
    tagName: {
      type: String,
      default: 'div'
    },
    modVertical: Boolean,
    modSingle: Boolean,
    modMultiple: Boolean
  },
  emits: ['input'],
  setup(props, { emit, slots }) {
    const children = computed(() => {
      return slots.default()
    })
    const activeTools = ref([])
    const value = ref(null)

    const classes = computed(() => {
      return [
        'vl-ol-map-tools-action-group',
        {
          'vl-ol-map-tools-action-group--vertical': props.modVertical
        }
      ]
    })

    watch(
      () => activeTools.value,
      () => {
        emit('input', activeTools.value)
      }
    )

    function setToolActive(tool) {
      activeTools.value.push(tool.id)
      tool.isActive = true
    }

    function setToolInactive(tool) {
      for (let i = 0; i < children.value.length; i++) {
        const child = children.value[i]
        if (child.el.id === tool.id) {
          for (let j = 0; j < activeTools.value.length; j++) {
            if (activeTools[j] === tool.id) {
              activeTools.value.splice(j, 1)
            }
          }
          child.props.isActive = false
        }
      }
      tool.isActive = false
    }

    function toggleTool(tool) {
      if (tool.isActive) {
        setToolInactive(tool)
      } else {
        setToolActive(tool)
      }
    }

    function getToolById(toolId) {
      for (let i = 0; i < children.value.length; i++) {
        const child = children.value[i]
        if (child.el.id === toolId) {
          return child
        }
      }
      return null
    }

    onMounted(() => {
      for (let i = 0; i < children.value.length; i++) {
        if (children.value[i].props?.isActive) {
          setToolActive(children.value[i])
        }
      }

      if (props.modMultiple || props.modSingle) {
        for (let i = 0; i < children.value.length; i++) {
          const child = children.value[i]
          if (child.props.classes.indexOf('vl-ol-map-tools-action') >= 0) {
            ;(child as any).on('change', () => {
              if (props.modSingle && activeTools.value.length > 0) {
                for (let k = 0; k < children.value.length; k++) {
                  if (children.value[k].el.id !== child.el.id) {
                    setToolInactive(children.value[k])
                  }
                }
              }
              toggleTool(child)
            })
          }
        }
      }
    })

    return {
      classes,
      setToolActive,
      setToolInactive,
      toggleTool,
      getToolById
    }
  }
})
