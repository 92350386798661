import  {App, Plugin} from 'vue';
import VlFormValidationObserver from '@/app/shared/components/vl-form-validation-observer/vl-form-validation-observer.vue';
import VlFormValidation from '@/app/shared/components/vl-form-validation/vl-form-validation.vue';
import VlOlMapComponents from '@/app/shared/components/vl-ol-map';
// import VlOlMapSearch from '@/app/shared/components/vl-ol-map/subcomponents/vl-ol-map-search/vl-ol-map-search.vue';

export default {
    install(app: App) {
        app.component('vl-form-validation-observer', VlFormValidationObserver);
        app.component('vl-form-validation', VlFormValidation);

        // app.component('vl-ol-map', VlOlMap);
        // app.component('vl-ol-map-search', VlOlMapSearch);

        app.use(VlOlMapComponents);
    }
} as Plugin;

export {
    VlFormValidationObserver,
    VlFormValidation,
    // VlOlMap,
};
