<template>
    <div
        class="vl-autocomplete js-vl-autocomplete"
        v-kl-click-outside="hideResults"
    >
        <span v-vl-visually-hidden v-html="helpText"></span>
        <vl-input-field
            :id="inputFieldId"
            v-bind="$attrs"
            :modelValue="searchText"
            @update:modelValue="updateModelValue"
            ref="inputFieldRef"
            autocomplete="off"
            @focus="showResults"
            @keydown.tab="hideResults"
            @keyup.esc="hideResults"
            @keydown.enter="selectFocussedResult"
            @keyup.down="arrowDown"
            @keyup.up="arrowUp"
        />
        <div class="vl-autocomplete" v-if="feedback">
            <div class="vl-autocomplete__feedback" v-html="feedback" />
        </div>
        <div v-if="fetching" class="vl-autocomplete__loader">
            <vl-loader />
        </div>
        <div
            class="vl-autocomplete"
            data-vl-content=""
            :aria-hidden="!hasResults.toString()"
            :data-vl-show="hasResults.toString()"
            v-if="hasResults"
            v-show="resultsVisible"
        >
            <div class="vl-autocomplete__list_wrapper">
                <ul class="vl-autocomplete__list" data-vl-records="" role="listbox">
                    <li
                        class="vl-autocomplete__item"
                        role="option"
                        v-for="(result, index) in results"
                        :key="result.value.name + '_' + index"
                    >
                        <a
                            :class="{'vl-autocomplete__cta': true, 'vl-autocomplete__cta--focus': result.selected}"
                            href="#"
                            tabindex="-1"
                            :data-vl-index="index + 1"
                            data-vl-record=""
                            data-vl-focus=""
                            :data-vl-value="result.title"
                            @click.prevent="select(result)"
                        >
                            <span class="vl-autocomplete__cta__title" v-html="result.title" />
                            <span class="vl-autocomplete__cta__sub" v-if="result.subtitle">{{ result.subtitle }}</span>
                        </a>
                    </li>
                </ul>
            </div>
        </div>
    </div>
</template>

<script lang="ts" src="./ippro-autocomplete.ts"></script>

<style lang="scss" scoped>
@import "./ippro-autocomplete.scss";
</style>
