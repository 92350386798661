import {validate} from 'vee-validate';
import {isEmpty} from 'lodash-es';

export async function klValidateRules(rules, newValue, params) {
    // console.log('_validateRules', rules, newValue, params)
    const result = await validate(newValue, rules, params);
    if (!isEmpty(result.errors)) {
        return result.errors[0];
    }
    return true;
}
