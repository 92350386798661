import {useUserStore} from '@/app/shared/state/UserDataModule';
import {EUserRole} from '@/app/shared/state/user-role';
import {
    ISettingsView,
    UpdateMriMailSettingsInputData,
    UpdateMailSettingsInput,
    IUpdateMailSettingsInput, IUpdateMriMailSettingsInputData
} from '@/api/klip-api.proxy';
import {computed, defineComponent, ref, watch} from 'vue';
import {useKlipApiProxy} from '@/plugins/proxy-client';

export default defineComponent({
    name: 'KlContactSettings',
    props: {
        settings: {
            type: Object as () => ISettingsView,
            default: undefined,
        }
    },
    setup(props) {

        const isSending = ref<boolean>(false);
        const hasChanged = ref<boolean>(false);

        const mriSettings = computed(() => {
            return props.settings ? props.settings.mriSettings : null;
        });

        const showMriSettings = computed(() => {
            return !!(useUserStore().hasRole([EUserRole.mri, EUserRole.klim_admin]) && mriSettings.value);
        });

        const submit = () => {
            const settings: IUpdateMailSettingsInput = {
                updateMriMailSettingsInput: null,
            };
            if (mriSettings.value) {
                const mriMailSettingsInputData: IUpdateMriMailSettingsInputData = {
                    confirmation: mriSettings.value.mailConfirmation,
                    incompletePackageAvailable: mriSettings.value.mailIncompletePackageAvailable,
                    soonToBeArchived: mriSettings.value.mailSoonToBeArchived
                };

                settings.updateMriMailSettingsInput = UpdateMriMailSettingsInputData.fromJS(mriMailSettingsInputData);
            }
            if (settings) {
                isSending.value = true;
                return useKlipApiProxy().settings_UpdateEmailSettings(UpdateMailSettingsInput.fromJS(settings))
                    .then(() => {
                        hasChanged.value = false;
                    })
                    .finally(() => {
                        isSending.value = false;
                    });
            }
        }

        watch(
            () => props.settings,
            (newSettings: ISettingsView, oldSettings: ISettingsView) => {
                if (newSettings && oldSettings) {
                    hasChanged.value = true;
                }
            },
            { immediate: false, deep: true });

        return {
            isSending,
            hasChanged,
            mriSettings,
            showMriSettings,
            submit,
        }
    }
})
