<div id="admin-settings-versions">
    <vl-region>
        <vl-form-grid mod-stacked>
            <vl-column width="8">
                <vl-title tag-name="h2">Versie nummers</vl-title>
            </vl-column>
            <vl-column width="4">
                <vl-action-group class="vl-action-group--align-right--l vl-action-group--primary">
                    <vl-button mod-medium @click="onCreateNewVersion">Nieuwe versie</vl-button>
                </vl-action-group>
            </vl-column>
        </vl-form-grid>
        <ippro-datatable class="versions" mod-zebra mod-hide-pagination :dataset="versions"
                         :columns="columns"  mod-pagination-on-top :meta="versionsMeta" :fetching="isVersionsDataTableLoading">
            <template v-slot:actions>
                <div v-vl-align:left>
                    <vl-button @click="toggleHistory" class="vl-button--link" icon="calendar" mod-icon-before>
                        {{ toggleHistoryButtonText }}
                    </vl-button>
                </div>
            </template>
        </ippro-datatable>
    </vl-region>

    <form class="vl-form" @submit="onSubmit">
        <vl-modal id="new-version-modal" mod-medium :closable="true">
            <vl-title tag-name="h3">Nieuwe versie</vl-title>
            <vl-form-grid mod-stacked>
                <vl-column>
                    <vl-link href="https://vlaamseoverheid.atlassian.net/wiki/spaces/KLIP/pages/834371956/Versie+beheer" icon="question-mark-filled" target="_blank" mod-icon-before >
                        Meer info over versiebeheer
                    </vl-link>
                </vl-column>
                <vl-column width-l="4">
                    <kl-field-validation-wrapper :field="activationDateField" mod-required v-slot="{ hasErrors }">
                        <kl-datepicker name="activationDate" v-model="activationDate" :min-date="activationMinDate" :mod-error="hasErrors" />
                    </kl-field-validation-wrapper>
                </vl-column>
                <vl-column width-l="8" v-vl-visibility:hide.s></vl-column>
                <vl-column width-l="6">
                    <kl-field-validation-wrapper :field="pmklVersionField" mod-required v-slot="{ hasErrors }">
                        <vl-input-field v-model="pmklVersion" name="pmklVersion" :mod-error="hasErrors" mod-block />
                    </kl-field-validation-wrapper>
                </vl-column>
                <vl-column width-l="6">
                    <kl-field-validation-wrapper :field="wfsVersionField" mod-required v-slot="{ hasErrors }">
                        <vl-input-field v-model="wfsVersion" name="wfsVersion" :mod-error="hasErrors" mod-block />
                    </kl-field-validation-wrapper>
                </vl-column>
                <vl-column width-l="6">
                    <kl-field-validation-wrapper :field="klipPackageField" mod-required v-slot="{ hasErrors }">
                        <vl-input-field v-model="klipPackage" name="klipPackage" :mod-error="hasErrors" mod-block />
                    </kl-field-validation-wrapper>
                </vl-column>
                <vl-column width-l="6">
                    <kl-field-validation-wrapper :field="minKlipPackageField" mod-required v-slot="{ hasErrors }">
                        <vl-input-field v-model="minKlipPackage" name="minKlipPackage" :mod-error="hasErrors" mod-block />
                    </kl-field-validation-wrapper>
                </vl-column>
                <vl-column width-l="6">
                    <kl-field-validation-wrapper :field="viewerField" mod-required v-slot="{ hasErrors }">
                        <vl-input-field v-model="viewer" name="viewer" :mod-error="hasErrors" mod-block />
                    </kl-field-validation-wrapper>
                </vl-column>
                <vl-column width-l="6">
                    <kl-field-validation-wrapper :field="minViewerField" mod-required v-slot="{ hasErrors }">
                        <vl-input-field v-model="minViewer" name="minViewer" :mod-error="hasErrors" mod-block />
                    </kl-field-validation-wrapper>
                </vl-column>
                <vl-column width-l="12">
                    <kl-field-validation-wrapper :field="releaseNotesField" mod-required v-slot="{ hasErrors }">
                        <vl-textarea v-model="releaseNotes" name="releaseNotes" rows="6" :mod-error="hasErrors" mod-block />
                    </kl-field-validation-wrapper>
                </vl-column>
            </vl-form-grid>
            <template v-slot:modal-footer>
                <vl-action-group>
                    <vl-button type="submit" mod-large :mod-loading="savingVersions"
                               :mod-disabled="savingVersions">Bewaren</vl-button>
                    <vl-button class="vl-button--link" v-vl-modal-toggle="'new-version-modal'" icon="cross" mod-icon-before>Annuleer</vl-button>
                </vl-action-group>
            </template>
        </vl-modal>
    </form>
</div>
