import { computed, defineComponent, watch, } from 'vue';
import { RegistrationModel, RegistrationType } from "@/app/public/registrationModel";

export default defineComponent({
    props: {
        registrationModel: {
            type: Object as () => RegistrationModel,
            required: true
        }
    },
    emits: ['change'],
    setup(props, { emit }) {
        const registration = computed({
            get: () => props.registrationModel.registrationType,
            set: (value) => {
                props.registrationModel.setRegistrationType(+value);
                emit('change', props.registrationModel);
            }
        });

        return {
            registration
        }
    }
});
