import { computed, defineComponent } from 'vue'
import { VlLoader } from '@govflanders/vl-ui-design-system-vue3'

export default defineComponent({
  name: 'VlOlMapLoader',
  components: {
    VlLoader
  },
  props: {
    tagName: {
      type: String,
      default: 'div'
    },
    modOverlay: Boolean,
    modBlocking: Boolean,
    title: {
      type: String,
      default: ''
    }
  },
  setup(props) {
    const classes = computed(() => {
      return [
        'vl-ol-map-loader',
        {
          'vl-ol-map-loader--blocking': props.modBlocking,
          'vl-ol-map-loader--overlay': props.modOverlay
        }
      ]
    })

    return {
      classes
    }
  }
})
