import {computed, defineComponent, onMounted, ref, watch} from "vue";
import * as KlipApi from "@/api/klip-api.proxy";
import { IAlertNotification } from '@/api/klip-api.proxy';
import { IpproDatatable } from "@/app/shared/components/ippro-datatable/ippro-datatable-declare";
import DateUtil from '@/app/shared/helpers/date-util';
import {useKlipApiProxy} from '@/plugins/proxy-client';
import {useField, useForm} from 'vee-validate';
import {useKlModalEventsUtil} from '@/app/shared/helpers/kl-modal-events-util';
import {first} from 'lodash-es';

export interface IMessageTypeMetaData {
    type: KlipApi.MessageType;
    translation: string,
    icon: string,
    modType: string,
    isClosable: boolean
}

export default defineComponent({
    setup() {
        const messageModalId = 'message-modal';

        const proxy = useKlipApiProxy();

        const messageModalEvents = useKlModalEventsUtil(messageModalId, {
            onClosed() {
                resetMessage();
            },
        });


        const form = useForm();

        const startDatesField = useField<string[]>('Start', 'required');
        const endDatesField = useField<string[]>('End');
        const titleField = useField<string>('Titel');
        const textField = useField<string>('Inhoud', 'required');

        const message = ref<KlipApi.UpdateMessageRequest & {startDates: string[], endDates: string[]}>(null);
        watch(() => message.value?.startDates, (newValue) => { startDatesField.value.value = newValue; });
        watch(() => message.value?.endDates, (newValue) => { endDatesField.value.value = newValue; });
        watch(() => message.value?.title, (newValue) => { titleField.value.value = newValue; });
        watch(() => message.value?.text, (newValue) => { textField.value.value = newValue; });


        const saving = ref<boolean>(false);
        const deleting = ref<boolean>(false);
        const isDataTableLoading = ref<boolean>(true);

        const messages = ref<KlipApi.MessageDto[]>([]);
        const meta = ref<{ totalRows: number, resultsPerPage: number, currentPage: number | undefined }>({ totalRows: 1, resultsPerPage: 1, currentPage: 1 });
        const today = DateUtil.now().format('YYYY-MM-DD 00:00:00');
        const activationMinDate = ref<string>(today);

        const typeMetadata: Record<KlipApi.MessageType, IMessageTypeMetaData> = {
            "Announcement": {
                type: KlipApi.MessageType.Announcement,
                translation: "Aankondiging",
                icon: "info-circle",
                modType: "mod-success",
                isClosable: true
            },
            "Warning":      {
                type: KlipApi.MessageType.Warning,
                translation: "Opgelet",
                icon: "warning",
                modType: "mod-warning",
                isClosable: false
            },
            "WhatsNew":     {
                type: KlipApi.MessageType.WhatsNew,
                translation: "Nieuw",
                icon: null,
                modType: null,
                isClosable: false
            }
        };

        const cellParser = (row: IRow, column: IColumn) => {
            let rowTemplate: string = `<div>${!!row[column.key] ? row[column.key] : ''}</div>`;
            switch (column.key) {
                case 'type': {
                    rowTemplate = `<div>${typeMetadata[row.type].translation}</div>`;
                    break;
                }
                case 'period': {
                    if (!!row.endTimestamp) {
                        rowTemplate = `<div>${DateUtil.formatDate(row.startTimestamp)} tot en met ${DateUtil.formatDate(row.endTimestamp)}</div>`;
                    } else {
                        rowTemplate = `<div>${DateUtil.formatDate(row.startTimestamp)}</div>`;
                    }

                    break;
                }
            }

            return {
                template: rowTemplate,
            };
        };

        const columns = ref<IColumn[]>([
            {
                key: 'type',
                label: 'Type',
                sortable: false,
                isVisible: true,
                width: 120,
                widthUnit: "px",
                parser: cellParser
            },
            {
                key: 'title',
                label: 'Titel',
                sortable: false,
                isVisible: true,
                width: 20,
                widthUnit: "em",
                parser: cellParser
            },
            {
                key: 'text',
                label: 'Inhoud',
                sortable: false,
                isVisible: true,
                width: 35,
                widthUnit: "em",
                parser: cellParser
            },
            {
                key: 'period',
                label: 'Periode',
                sortable: false,
                isVisible: true,
                parser: cellParser,
                width: 150,
                widthUnit: "px"
            }
        ]);

        const isNewMessage = computed(() => {
            return !message.value.messageId  || message.value.messageId <= 0;
        });

        const isWhatsNew = computed(() => {
            return message.value && message.value.type === KlipApi.MessageType.WhatsNew;
        });

        const isHeaderMessage = computed(() => {
            return message.value && (message.value.type === KlipApi.MessageType.Announcement || message.value.type === KlipApi.MessageType.Warning);
        });

        const headerMessages = computed(() => {
            if (!isHeaderMessage.value) return [];

            return [
                <IAlertNotification> {
                    key: message.value.messageId,
                    icon: typeMetadata[message.value.type].icon,
                    isClosable: typeMetadata[message.value.type].isClosable,
                    type: typeMetadata[message.value.type].modType,
                    title: message.value.title,
                    message: message.value.text
                }
            ];
        });

        const loadMessages = async () => {
            const response = await proxy.operations_Messages();
            if(response.isSuccessStatusCode){
                messages.value = response.result;

                meta.value.currentPage = 1;
                meta.value.resultsPerPage = response.result.length;
                meta.value.totalRows = response.result.length;

                isDataTableLoading.value = false;
            }
        };

        const rowClicked = async (row: IpproDatatable.IRow) => {
            message.value = <KlipApi.UpdateMessageRequest & {startDates: string[], endDates: string[]}> {
                messageId: row.messageId,
                type: row.type,
                startTimestamp: row.startTimestamp,
                startDates: [DateUtil.formatDate(row.startTimestamp)],
                endTimestamp: row.endTimestamp,
                endDates: !!row.endTimestamp ? [DateUtil.formatDate(row.endTimestamp)] : [],
                title: row.title,
                text: row.text
            };

            messageModalEvents.triggerModalToggle();
        };

        const newMessage = async () => {
            resetMessage();
            messageModalEvents.triggerModalToggle();
        };

        const resetMessage = () => {
            message.value = <KlipApi.UpdateMessageRequest & {startDates: string[], endDates: string[]}> {
                messageId: null,
                type: KlipApi.MessageType.Announcement,
                startTimestamp: today,
                startDates: [today],
                endTimestamp: null,
                endDates: null,
                title: null,
                text: null
            };

            form.resetForm();
        };

        const _saveMessage = async () => {
            saving.value = true;
            message.value.startTimestamp = DateUtil.formatForApi(first(message.value.startDates), 'YYYY-MM-DD 00:00:00');
            message.value.endTimestamp = DateUtil.formatForApi(first(message.value.endDates), 'YYYY-MM-DD 00:00:00');
            await proxy.operations_UpdateMessage(message.value);
            saving.value = false;

            messageModalEvents.triggerModalToggle();

            resetMessage();
            await loadMessages();
        };

        const deleteMessage = async () => {
            deleting.value = true;
            await proxy.operations_DeleteMessage(message.value.messageId);
            deleting.value = false;

            messageModalEvents.triggerModalToggle();


            resetMessage();
            await loadMessages();
        };

        onMounted(async () => {
            resetMessage();
            await loadMessages();
        });

        const _onInvalidSubmit = (submittedValues) => {
            console.log('_onInvalidSubmit', submittedValues);
        }

        const onSubmit = form.handleSubmit(_saveMessage, _onInvalidSubmit);

        return {
            startDatesField,
            endDatesField,
            titleField,
            textField,

            isDataTableLoading,
            saving,
            cellParser,
            columns,
            meta,
            messages,
            message,
            activationMinDate,
            rowClicked,
            messageModalId,
            typeMetadata,
            newMessage,
            deleting,
            deleteMessage,
            isNewMessage,
            isWhatsNew,
            isHeaderMessage,
            headerMessages,

            onSubmit,
        };
    }
});
