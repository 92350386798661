<ippro-map ref="map" mod-toggle-fullscreen :mod-layers="['grb_bsk', 'omwrgbmrvl']" class="kl-maprequest-map">
    <!-- tile navigation-->
    <template v-slot:tile-navigation>
        <vl-tile-navigation-item icon="download-harddisk" @click="toggleSidebar" label="Downloads" mod-active
                                 v-if="downloadUrls"/>
    </template>

    <!-- sidebar-->
    <template v-slot:sidebar>
        <vl-sidebar ref="sidebar" v-if="downloadUrls" mod-flyout mod-collapsible class="kl-maprequest-map__sidebar">
            <vl-sidebar-content>
                <kl-maprequest-download :download-urls="downloadUrls" v-if="downloadUrls"></kl-maprequest-download>
            </vl-sidebar-content>
        </vl-sidebar>
    </template>

    <!-- zoom-to-geometry-->
    <template v-slot:view-tools>
        <vl-ol-map-tools-action-group mod-vertical v-if="geometry">
            <vl-ol-map-tools-action @click.prevent="zoomToMapRequestZone" ref="zoomToMapRequestZoneButton"
                                 text="Zoom naar planaanvraagzone"
                                 v-vl-tooltip.left.hide-immediately="'Zoom&nbsp;naar&nbsp;planaanvraagzone'">
                <template v-slot:icon>
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 22 22">
                        <g fill="none" fill-rule="nonzero">
                            <path fill="#FFF" d="M0 0v22h22V11H11z"/>
                            <path fill="#ABABAB"
                                  d="M19.49 11a7.55 7.55 0 01-2.26 2H20v7H2V4.83l4.6 4.59C6.2 8.5 6 7.5 6 6.5v-.48l-6-6V22h22V11h-2.51z"/>
                            <path fill="#545454"
                                  d="M14 4h-1v2h-2v1h2v2h1V7h2V6h-2V4zm-.5-4a6.49 6.49 0 00-5.18 10.42l-3 3a1 1 0 101.41 1.41l3.05-3.05A6.5 6.5 0 1013.5 0zm0 11a4.5 4.5 0 110-9 4.5 4.5 0 010 9z"/>
                        </g>
                    </svg>
                </template>
            </vl-ol-map-tools-action>
        </vl-ol-map-tools-action-group>
    </template>
</ippro-map>
