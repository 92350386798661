<template>
    <vl-datepicker
        v-bind="$attrs"
        :placeholder="placeholder"
        :visual-format="visualFormat"
        :format="'d/m/Y'"
        :modelValue="modelValue"
        @update:modelValue="onUpdate"
    />
</template>

<script lang="ts" src="./kl-datepicker.ts">
</script>

<!--<script lang="ts" src="./kl-datepicker.ts">-->
<!--</script>-->
