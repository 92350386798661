import { computed, defineComponent } from 'vue'

export default defineComponent({
  name: 'VlOlMapToolsAction',
  compatConfig: {
    MODE: 3
  },
  props: {
    tagName: {
      type: String,
      default: 'button'
    },
    icon: {
      type: String,
      default: ''
    },
    id: {
      type: String,
      default: ''
    },
    name: {
      type: String,
      default: ''
    },
    modDisabled: Boolean,
    modToggle: Boolean,
    modRadio: Boolean,
    modActive: Boolean,
    a11yLabel: {
      type: String,
      default: ''
    }
  },
  emits: ['change'],
  setup(props, { emit, attrs }) {
    const classes = computed(() => {
      return [
        'vl-ol-map-tools-action',
        {
          'vl-ol-map-tools-action--active': props.modActive,
          'vl-ol-map-tools-action--disabled': props.modDisabled
        }
      ]
    })

    function clicked(e) {
      if (e.target.tagName !== 'A') {
        emit('change')
      }
    }

    return {
      classes,
      clicked
    }
  }
})
