import {useSettingsStore} from '@/app/settings/settings-store';
import { OrganisationUnaSettings } from '@/api/klip-api.proxy';
import {useUserStore} from '@/app/shared/state/UserDataModule';
import {EUserRole} from '@/app/shared/state/user-role';
import { EditUnaZone, NewUnaZone } from '@/app/settings/components/kl-organisation-settings/kl-organisation-routes';
import { EditUnaKlimZone, NewUnaKlimZone, } from '@/app/settings/components/kl-organisation-settings/kl-organisation-routes';
import { UnaKlimZone, UnaZone } from '@/app/settings/components/kl-organisation-settings/kl-organisation-routes'
import * as KlipApi from '@/api/klip-api.proxy';
import {computed, defineComponent, onMounted, ref} from 'vue';
import {useRoute} from '@/router/router-vue3';
import {IDrawZoneMapGeometry} from '@/app/shared/components/kl-draw-zone-map/kl-draw-zone-map';
import {canCurrentUserAccessRoute, safeRouteNameToString} from '@/router/route-utils';
import {RouteRecordRaw} from 'vue-router';

export default defineComponent({
    name: 'KlOrganisationUnaZones',
    props: {
        value: {
            type: Object as () => OrganisationUnaSettings,
            default: () => ({}),
            required: true
        },
    },
    setup(props) {

        const route = useRoute();

        const zonesFetching = ref<boolean>(true);

        const displayName = computed((): string => props.value.displayName);

        // @Getter(SettingsStore.store.getters.namespaceZones, { namespace: SettingsStore.store.namespace })
        const _namespaceZones = computed((): KlipApi.IZoneNamespace[] => useSettingsStore().namespaceZones);

        // @Action(SettingsStore.store.actions.loadNameSpaceZone, { namespace: SettingsStore.store.namespace })
        const _loadNameSpaceZone = (organisationId: string) => useSettingsStore().loadNameSpaceZone(organisationId);

        const drawZones = computed(() => {
            const today = new Date();

            const myNamespaces = namespaces.value;
            return myNamespaces.map((cns, index): IDrawZoneMapGeometry => {

                const activationDate = new Date(cns.zoneGeometryActivationDate);
                const isActive = cns.isKlimZone || !cns.zoneGeometryActivationDate || (activationDate < today);

                return {
                    name: cns.zoneName,
                    wkt: cns.zoneGeometry,
                    orgZoneInfo: {
                        isKlim: cns.isKlimZone,
                        guid: cns.zoneId,
                        namespace: cns.namespace,
                        isActive: isActive,
                        isArchived: cns.isArchived,
                        willBeActivatedOn: !isActive ? activationDate : undefined,
                        editLink: {
                            name: safeRouteNameToString(cns.link?.route?.name),
                            params: { zoneId: cns.zoneId },
                        },
                    }
                }
            });
        });

        const canAddKlbZone = computed(() => {
            return editable.value && canCurrentUserAccessRoute(routeUnaZoneCreate.value);
        });

        const canAddKlimZone = computed(() => {
            return editable.value && !hasKlimZone.value && canCurrentUserAccessRoute(routeUnaKlimZoneCreate.value);
        });


        const namespaces = computed(() => {
            return _mapNamespaces(_namespaceZones.value);
        });

        const hasKlimZone = computed(() => {
            return _namespaceZones.value.filter(n => n.isKlimZone).length === 1;
        });

        const routeEditUnaZone = computed((): RouteRecordRaw => {
            return EditUnaZone;
        });

        const routeUnaZoneCreate = computed(() => {
            return NewUnaZone;
        });

        const routeUnaZone = computed((): RouteRecordRaw => {
            return UnaZone;
        });

        const routeUnaKlimZone = computed((): RouteRecordRaw => {
            return UnaKlimZone;
        });

        const routeUnaKlimZoneCreate = computed(() => {
            return NewUnaKlimZone;
        });

        const routeEditUnaKlimZone = computed((): RouteRecordRaw => {
            return EditUnaKlimZone;
        });

        const editable = computed((): boolean => {
            return useUserStore().hasRole([EUserRole.una_manager, EUserRole.klim_admin]);
        });

        const isKlimAdmin = computed((): boolean => {
            return useUserStore().hasRole([EUserRole.klim_admin]);
        });



        const _mapNamespaces = (namespaces: KlipApi.IZoneNamespace[]) => {
            if (!namespaces) {
                return [];
            }

            return namespaces
                .filter((item) => item.zoneId !== route.params?.removedItem)
                .sort((a, b) => Number(b.isKlimZone) - Number(a.isKlimZone))
                .map((namespace) => ({
                    ...namespace,
                    link: {
                        route: namespace.isKlimZone ? _getRouteKlim() : _getRouteUna(),
                        params: {
                            organisationId: props.value.organisationId,
                            zoneId: namespace.zoneId,
                        },
                    },
                }));
        }

        const _getRouteKlim = (): RouteRecordRaw => {
            if (editable.value && isKlimAdmin.value) {
                return routeEditUnaKlimZone.value;
            } else {
                return routeUnaKlimZone.value;
            }
        }

        const _getRouteUna = (): RouteRecordRaw => {
            if (editable.value) {
                return routeEditUnaZone.value;
            } else {
                return routeUnaZone.value;
            }
        }

        onMounted(() => {
            //displayName.value = props.value.displayName;
            _loadNameSpaceZone(props.value.organisationId).then(() => {
                zonesFetching.value = false;
            });
        });

        return {
            displayName,
            zonesFetching,
            namespaces,
            hasKlimZone,
            routeEditUnaZone,
            routeUnaZoneCreate,
            routeUnaZone,
            routeUnaKlimZone,
            routeUnaKlimZoneCreate,
            routeEditUnaKlimZone,
            editable,
            isKlimAdmin,

            drawZones,
            canAddKlbZone,
            canAddKlimZone,
        }
    }
})
