<div v-vl-highlight-content>
<!--    <vl-form-validation-observer v-slot="{ handleSubmit, invalid, pristine }">-->
        <form @submit="onSubmit">
            <vl-form-grid>
                <vl-column>
                    <fieldset>
                        <vl-title tag-name="h3">Activering geometrie</vl-title>
                        <vl-form-grid mod-stacked>
                            <vl-column>
                                <slot name="content"></slot>
                            </vl-column>
                            <vl-column width="3">
                                <kl-field-validation-wrapper :field="geometryActivationDateField" #default="{hasErrors}" mod-required>
                                    <kl-datepicker name="activeringsdatum" id="date-activate"
                                        :min-date="geometryActivationMinDate" v-model="steps.activation.fields.geometryActivationDate"
                                        :mod-error="hasErrors" />
                                </kl-field-validation-wrapper>
                            </vl-column>
                        </vl-form-grid>
                    </fieldset>
                </vl-column>
                <vl-u-spacer />
                <vl-column>
                    <fieldset>
                        <vl-title tag-name="h3">Uw referenties</vl-title>
                        <vl-form-grid mod-stacked>
                            <vl-column>
                                <p>KLIP doet niets met deze optionele gegevens. U kan deze gebruiken om de opgeladen geometrie
                                    achteraf
                                    te identificeren.</p>
                            </vl-column>
                            <vl-column width="6" width-m="9" width-s="12">
                                <kl-field-validation-wrapper :field="geometryReferenceField" #default="{hasErrors}">
                                    <vl-input-field name="referentie" id="reference"
                                        v-model="steps.reference.fields.geometryReference" :mod-error="hasErrors" mod-block/>
                                </kl-field-validation-wrapper>
                            </vl-column>
                            <vl-column width="6" width-m="3" width-s="12" v-vl-visibility:hide.s></vl-column>
                            <vl-column width="2" width-m="3" width-s="4" width-xs="6">
                                <kl-field-validation-wrapper :field="geometryVersionField" #default="{hasErrors}">
                                    <vl-input-field name="versie" id="version" v-model="steps.reference.fields.geometryVersion"
                                        :mod-error="hasErrors" mod-block />
                                </kl-field-validation-wrapper>
                            </vl-column>
                            <vl-column width="2" width-m="3" width-s="4" width-xs="12">
                                <kl-field-validation-wrapper :field="geometryPrecisionField" #default="{hasErrors}">
                                    <vl-input-group>
                                        <vl-input-field type="number" name="nauwkeurigheid" id="accuracy"
                                            v-model="steps.reference.fields.geometryPrecision" :mod-error="hasErrors"
                                            mod-block />
                                        <vl-input-addon tooltip="In meter" text="m" />
                                    </vl-input-group>
                                </kl-field-validation-wrapper>
                            </vl-column>
                            <vl-column width="2" width-m="3" width-s="4" width-xs="12">
                                <kl-field-validation-wrapper :field="geometryLastModificationDateField" #default="{hasErrors}">
                                    <kl-datepicker name="laatstbewerkt" id="date-modified"
                                        :max-date="geometryLastModificationMaxDate" v-model="steps.reference.fields.geometryLastModificationDate"
                                        :mod-error="hasErrors" />
                                </kl-field-validation-wrapper>
                            </vl-column>
                        </vl-form-grid>
                    </fieldset>
                </vl-column>
                <vl-u-spacer />
                <vl-column>
                    <fieldset>
                        <vl-title tag-name="h3">Uw geometrie</vl-title>
                        <vl-form-grid mod-stacked>
                            <vl-column>

                              <kl-draw-zone-map
                                  :mod-maprequest-zone="false"
                                  :for-geometry="true"
                                  :mod-enable-multi-zones="false"
                                  :no-confirmation="true"
                                  :mod-show-km-hm-toggle="false"
                                  rules=""
                                  :mod-disabled="validatingGeometry"
                                  @input="onNewGeometryChanged"
                              />

                            </vl-column>
                        </vl-form-grid>
                    </fieldset>
                </vl-column>
                <vl-column key="kl-create-geometry-actions" v-if="showActions">
                    <vl-u-spacer mod-medium />
                    <vl-action-group>
                        <vl-button type="submit" :mod-disabled="disableSubmit">Geometrie toevoegen</vl-button>
                        <vl-button @click="revert" v-if="modRevertable" mod-icon-before mod-tertiary>Annuleren</vl-button>
                    </vl-action-group>
                </vl-column>
                <vl-column v-if="geometryErrorMessages?.length">
                    <vl-u-spacer/>
                    <ippro-icon-text v-for="(errorMessage) in geometryErrorMessages" icon="alert-circle" v-vl-text.error>
                        {{errorMessage}}
                    </ippro-icon-text>
                </vl-column>
            </vl-form-grid>
        </form>
<!--    </vl-form-validation-observer>-->
</div>
