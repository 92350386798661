<template>
    <component :is="tagName" :class="classes" :disabled="modDisabled" v-bind="$attrs" @click="clicked">
        <div v-if="!!$slots.icon" class="vl-ol-map-tools-action__svg-icon">
            <slot name="icon" />
        </div>
        <vl-icon v-if="icon" :icon="icon" class="vl-ol-map-tools-action__icon" />
        <span class="vl-ol-map-tools-action__text">{{ a11yLabel }}</span>
    </component>
</template>
<script src="./vl-ol-map-tools-action.ts"></script>
