import {computed, defineComponent, onMounted, ref} from "vue";
import * as KlipApi from "@/api/klip-api.proxy";
import {useField, useForm} from 'vee-validate';
import {useKlipApiProxy} from '@/plugins/proxy-client';
import KlFieldValidationWrapper2 from '@/app/shared/components/kl-form-fields/kl-field-validation-wrapper.vue';
import {first, isEmpty} from 'lodash-es';
import {useKlModalEventsUtil} from '@/app/shared/helpers/kl-modal-events-util';
import DateUtil from '@/app/shared/helpers/date-util';

export default defineComponent({
    name: 'KlAdminVersions',
    components: {
        KlFieldValidationWrapper2,
    },
    setup() {

        const modalEvents = useKlModalEventsUtil('new-version-modal', {
            onClosed() {
                savingVersions.value = false;
            },
        });

        const { values, handleSubmit, defineField, errors,  } = useForm({
            // keepValuesOnUnmount: true,
        });

        const activationDateField = useField<string[]>('Activatie datum', 'required');
        const klipPackageField = useField<string>('KLIP Package', 'required');
        const pmklVersionField = useField<string>('PMKL', 'required');
        const wfsVersionField = useField<string>('WFS', 'required');
        const minViewerField = useField<string>('Minimum Viewer', 'required');
        const viewerField = useField<string>('Viewer', 'required');
        const minKlipPackageField = useField<string>('Minimum KLIP Package', 'required');
        const releaseNotesField = useField<string>('Release Notes', 'required');

        const activationDate = activationDateField.value;
        const klipPackage = klipPackageField.value;
        const pmklVersion = pmklVersionField.value;
        const wfsVersion = wfsVersionField.value;
        const minViewer = minViewerField.value;
        const viewer = viewerField.value;
        const minKlipPackage = minKlipPackageField.value;
        const releaseNotes = releaseNotesField.value;

        const proxy = useKlipApiProxy();
        const today = DateUtil.formatDate(DateUtil.now());

        const activationMinDate = ref<string>(today);
        const savingVersions = ref<boolean>(false);
        const isVersionsDataTableLoading = ref<boolean>(true);
        const allVersions = ref<KlipApi.IVersionDto[]>([]);
        const showAllVersions = ref<boolean>(false);
        const versionsMeta = ref<{ totalRows: number, resultsPerPage: number, currentPage: number | undefined }>({ totalRows: 1, resultsPerPage: 1, currentPage: 1 });


        const versions = computed((): KlipApi.IVersionDto[] => {
            if (isEmpty(allVersions.value)) {
                return [];
            }
            return showAllVersions.value ? allVersions.value : [allVersions.value[0]];
        });

        const cellParser = (row: IRow, column: IColumn) => {
            if(row[column.key])
            {
                return {
                    template: `<div>${DateUtil.formatDate(row[column.key])}</div>`,
                };

            }
            return {
                template: `<div>&nbsp;</div>`
            };
        };

        const columns = ref<IColumn[]>([
            {
                key: 'activationDate',
                label: 'Activatie datum',
                sortable: false,
                width: 10,
                isVisible: true,
                parser: cellParser,
            },
            {
                key: 'pmklVersion',
                label: 'Pmkl',
                sortable: false,
                width: 5,
                isVisible: true
            },
            {
                key: 'wfsVersion',
                label: 'WFS',
                width: 5,
                sortable: false,
                isVisible: true,
            },
            {
                key: 'packageVersion',
                label: 'Package',
                sortable: false,
                width: 5,
                isVisible: true,
            },
            {
                key: 'minimumPackageVersion',
                label: 'MinPackage',
                sortable: false,
                width: 5,
                isVisible: true,
            },
            {
                key: 'viewerVersion',
                label: 'Viewer',
                sortable: false,
                width: 5,
                isVisible: true,
            },
            {
                key: 'minimumViewerVersion',
                label: 'MinViewer',
                sortable: false,
                width: 5,
                isVisible: true,
            },
            {
                key: 'releaseNotes',
                label: 'ReleaseNotes',
                sortable: false,
                width: 20,
                isVisible: true,
            }
        ]);

        const toggleHistoryButtonText = computed(() => {
           return showAllVersions.value ? 'Verberg historiek' : 'Toon historiek';
        });

        const toggleHistory = () => {
            showAllVersions.value = !showAllVersions.value;
        };

        const addVersionToUi = () => {
            allVersions.value.unshift({
                activationDate: DateUtil.formatForApi(activationDate.value[0], 'YYYY-MM-DD 00:00:00'),
                packageVersion: klipPackage.value,
                pmklVersion: pmklVersion.value,
                wfsVersion: wfsVersion.value,
                minimumViewerVersion: minViewer.value,
                viewerVersion: viewer.value,
                minimumPackageVersion: minKlipPackage.value,
                releaseNotes: releaseNotes.value,
            });
        }

        const _onInvalidSubmit = (submittedValues) => {
        }

        const _saveVersion = async () => {
            savingVersions.value = true;

            const newVersion: KlipApi.IAddVersionRequest = {
                activationDate: DateUtil.formatForApi(activationDate.value[0], 'YYYY-MM-DD 00:00:00'),
                packageVersion: klipPackage.value,
                pmklVersion: pmklVersion.value,
                wfsVersion: wfsVersion.value,
                minimumViewerVersion: minViewer.value,
                viewerVersion: viewer.value,
                minimumPackageVersion: minKlipPackage.value,
                releaseNotes: releaseNotes.value,
            };

            await proxy.operations_AddVersion(KlipApi.AddVersionRequest.fromJS(newVersion));
            addVersionToUi();

            savingVersions.value = false;

            modalEvents.triggerModalToggle();
        };

        const onCreateNewVersion = () => {
            const latestVersion = first(allVersions.value);

            activationDate.value = [today];
            pmklVersion.value = latestVersion?.pmklVersion;
            wfsVersion.value = latestVersion?.wfsVersion;
            klipPackage.value = latestVersion?.packageVersion;
            minKlipPackage.value = latestVersion?.minimumPackageVersion;
            minViewer.value = latestVersion?.minimumViewerVersion;
            viewer.value = latestVersion?.viewerVersion;

            releaseNotesField.resetField();

            modalEvents.triggerModalToggle();
        }

        const _loadVersions = async () => {
            const response = await proxy.operations_GetVersions();
            if (response.isSuccessStatusCode) {
                allVersions.value = response.result;
                versionsMeta.value.totalRows = response.result.length;
                versionsMeta.value.resultsPerPage = response.result.length;
                isVersionsDataTableLoading.value = false;
            }
        };

        onMounted(async () => {
            await _loadVersions();
        });

        const onSubmit = handleSubmit(_saveVersion, _onInvalidSubmit);

        return {
            isVersionsDataTableLoading,
            savingVersions,
            showAllVersions,
            versions,
            activationMinDate,

            columns,
            versionsMeta,
            toggleHistory,
            toggleHistoryButtonText,

            onCreateNewVersion,

            onSubmit,

            activationDateField,
            pmklVersionField,
            wfsVersionField,
            klipPackageField,
            minKlipPackageField,
            viewerField,
            minViewerField,
            releaseNotesField,

            activationDate,
            pmklVersion,
            wfsVersion,
            klipPackage,
            minKlipPackage,
            viewer,
            minViewer,
            releaseNotes,
        };
    }
});
