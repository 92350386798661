import {computed, defineComponent, inject, onMounted, ref} from "vue";
import * as KlipApi from "@/api/klip-api.proxy";
import DateUtil from '@/app/shared/helpers/date-util';

export default defineComponent({
    setup() {
        const proxy = inject<KlipApi.IKlipApiProxy>('klipApiProxy');
        const isPricesDataTableLoading = ref<boolean>(true);
        const pricesMeta = ref<{ totalRows: number, resultsPerPage: number, currentPage: number | undefined }>({ totalRows: 1, resultsPerPage: 1, currentPage: 1 });
        const showAllVersions = ref<boolean>(false);
        const prices = ref<KlipApi.PriceDto[]>();
        const allPrices = ref<KlipApi.PriceDto[]>();

        const dateCellParser = (row: IRow, column: IColumn) => {
            if(row[column.key])
            {
                return {
                    template: `<div>${DateUtil.formatDate(row[column.key])}</div>`,
                };

            }
            return {
                template: `<div>&nbsp;</div>`
            };
        };

        const priceCellParser = (row: IRow, column: IColumn) => {
            if(row[column.key])
            {
                return {
                    template: `<div>&euro;${row[column.key]}</div>`,
                };

            }
            return {
                template: `<div>&nbsp;</div>`
            };
        };

        const areaCellParser = (row: IRow, column: IColumn) => {
            if(row[column.key])
            {
                return {
                    template: `<div>${row[column.key]}m<sup>2</sup></div>`,
                };

            }
            return {
                template: `<div>&nbsp;</div>`
            };
        };


        const columns = ref<IColumn[]>([
            {
                key: 'activationDate',
                label: 'Activatie datum',
                sortable: false,
                isVisible: true,
                parser: dateCellParser
            },
            {
                key: 'smallAreaPrice',
                label: 'Prijs kleine planaanvraag',
                sortable: false,
                isVisible: true,
                parser: priceCellParser

            },
            {
                key: 'largeAreaPrice',
                label: 'Prijs grote planaanvraag',
                sortable: false,
                isVisible: true,
                parser: priceCellParser
            },
            {
                key: 'areaThreshold',
                label: 'Oppervlakte drempelwaarde',
                sortable: false,
                isVisible: true,
                parser: areaCellParser
            },
            {
                key: 'imklPrice',
                label: 'Prijs Imkl',
                sortable: false,
                isVisible: true,
                parser: priceCellParser
            }
        ]);

        const toggleHistoryButtonText = computed(() => {
            return showAllVersions.value ? 'Verberg historiek' : 'Toon historiek';
        });

        const toggleHistory = () => {
            const show = !showAllVersions.value
            if(show) {
                prices.value = allPrices.value;
            }else{
                prices.value = [allPrices.value[0]];
            }
            showAllVersions.value = show;
        };

        const loadPrices = async () => {
            const response  = await proxy.operations_Prices();
            if(response.isSuccessStatusCode){
                const latestPrice =response.result[0];
                allPrices.value = response.result;
                prices.value = [latestPrice];
                pricesMeta.value.totalRows = response.result.length;
                pricesMeta.value.resultsPerPage = response.result.length;
                isPricesDataTableLoading.value = false;
            }
        };

        onMounted(async () => {
            await loadPrices();
        });

        return {
            columns,
            pricesMeta,
            isPricesDataTableLoading,
            prices,
            toggleHistoryButtonText,
            toggleHistory,
        }
    }
});
