<script setup lang="ts">
import {ref} from 'vue';

const options = [{
    name: 'radio-tile-name-01',
    value: 'one',
    id: 'one',
    title: 'Radio - choice 1',
    info: 'Describe the option here'
}, {
    name: 'radio-tile-name-02',
    value: 'two',
    id: 'two',
    title: 'Radio - choice 2',
    info: 'Describe the option here'
}];
const picked = ref(null);

</script>

<template>
    <div>
        <vl-radio-tile
            v-model="picked"
            v-for="(radioTile) in options"
            :name="radioTile.name"
            :value="radioTile.value"
            :id="radioTile.id"
            :title="radioTile.title"
            :info="radioTile.info"
            :key="radioTile.name"
        >
        </vl-radio-tile><br />
        Picked value: {{ picked ?? 'Choose an option above' }}
    </div>
</template>

<style scoped lang="scss">

</style>
