import { defineComponent, inject, onBeforeUnmount, onMounted, provide, Ref, watch } from 'vue'
import TileLayer from 'ol/layer/Tile'
import { VlOlMapLayer } from '../vl-ol-map-layer/vl-ol-map-layer-composable'
import { Map } from 'ol'

export default defineComponent({
  compatConfig: {
    MODE: 3
  },

  name: 'VlOlMapTileLayer',
  props: {
    visible: {
      type: Boolean,
      default: true
    }
  },
  setup(props) {
    const olMap: Ref<Map> = inject('olMap')
    const { layer, source } = VlOlMapLayer(olMap)
    onMounted(() => {
      layer.value = new TileLayer()
      layer.value?.setVisible(props.visible)
    })
    onBeforeUnmount(() => {
      olMap.value.removeLayer(layer.value)
    })
    watch(
      () => props.visible,
      (newVisible) => {
        layer.value?.setVisible(newVisible)
      },
      { immediate: true }
    )
    provide('source', source)
  }
})
