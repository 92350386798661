import { computed, ComputedRef, defineComponent, getCurrentInstance, onMounted, reactive, watch } from 'vue'
import Select from 'ol/interaction/Select'

export default defineComponent({
  name: 'VlOlMapSelectInteraction',
  emits: ['select'],
  setup(props, { emit }) {
    const data = reactive({
      select: undefined,
      style: undefined
    })
    const map: ComputedRef<any> = computed(() => {
      const instance = getCurrentInstance()
      return instance?.parent?.data.map
    })
    watch(
      () => map.value,
      () => {
        map.value?.addInteraction(data.select)
        data.select &&
          data.select.on('select', (e: any) => {
            e.selected.flatMap((feature: any) => feature.get('features') || feature).forEach((feature: any) => feature.set('selected', true))
            e.deselected.flatMap((feature: any) => feature.get('features') || feature).forEach((feature: any) => feature.set('selected', false))
            emit('select', e)
          })
      }
    )
    onMounted(() => {
      if (!data.select && Select) {
        data.select = new Select({
          multi: true,
          layers: (layer: any) => layer === getCurrentInstance()?.parent?.data.layer,
          style: data.style
            ? (feature: any) => {
                try {
                  return data.style(feature)
                } catch {
                  return data.style
                }
              }
            : undefined
        })
      }
    })
  }
})
