<div v-vl-align:center v-if="viewModelLoading">
    <vl-loader message="De gegevens worden geladen. Even geduld, a.u.b." />
</div>

<div v-else class="admin-requested-maprequests">
    <vl-form-grid mod-stacked mod-v-center>
        <vl-column>
            <kl-search-maprequests v-if="mapRequestsSearchFields" ref="searchMaprequests"
                                   @search="search"
                                   :search-fields="mapRequestsSearchFields"
                                   :search-options="mapRequestsSearchOptions"
                                   :default-search-option="mapRequestDefaultSearchOption"
                                   :default-filter="defaultFilter">
                <template v-slot:operations>
                    <vl-form-message-label for="organisations" v-vl-visually-hidden>Organisations</vl-form-message-label>
                    <vl-select name="organisations" v-model="selectedOrganisation" mod-block>
                        <option value="all">Alle organisaties</option>
                        <optgroup label="Suborganisaties">
                            <option :value="option.key" v-for="option in organisations">
                                <span v-html="setDepth(option.order, option.label)"></span>
                            </option>
                        </optgroup>
                    </vl-select>
                </template>
                <template v-slot:zones v-if="!ismapRequestTypeRequested && zones && zones.length > 1">
                    <vl-form-message-label for="zones" v-vl-visually-hidden>Zones</vl-form-message-label>
                    <vl-select name="zones" v-model="selectedZone" mod-block>
                        <option value="all">Alle zones</option>
                        <option :value="option.key" v-for="option in zones">
                            {{ option.label }}
                        </option>
                    </vl-select>
                </template>
                <template v-slot:movemaprequest v-if="mapRequestsMeta.totalRows > 0 && isAdmin && ismapRequestTypeRequested">
                    <vl-checkbox :modelValue="moveMapRequests" @update:modelValue="moveMapRequestsChanged">Ik wil planaanvragen verplaatsen</vl-checkbox>
                    &nbsp;&nbsp;
                    <vl-button @submit.prevent v-if="moveMapRequests" v-vl-modal-toggle="'move-map-request-modal'" :mod-disabled="hasCheckedMapRequest">Verplaats planaanvragen</vl-button>
                    <kl-move-map-requests @move="onMoveMapRequests" />
                </template>
            </kl-search-maprequests>
        </vl-column>

        <vl-column>
            <ippro-datatable id="organisation-requested-maprequests"
                             :meta="mapRequestsMeta"
                             :dataset="mapRequests"
                             :columns="mapRequestsColumns"
                             :mod-checkable-rows="moveMapRequestChecked"
                             :fetching="mapRequestsFetching"
                             @checkbox-change="checkboxChanged"
                             @pager-clicked="pagerClicked"
                             @column-clicked="columnClicked"
                             @row-clicked="rowClicked" mod-clickable-rows mod-zebra mod-pagination-on-top>
                <template v-slot:topactions>
                    <vl-link v-vl-modal-toggle="'move-map-request-modal'" v-if="moveMapRequests && mapRequestsMeta.totalRows > 0">
                        <vl-icon icon="move-left-right" class="vl-link__icon vl-link__icon--before" />Verplaats alle {{mapRequestsMeta.totalRows}} planaanvragen.
                    </vl-link>
                </template>
                <template v-slot:actions>
                    <vl-link v-vl-modal-toggle="'move-map-request-modal'" v-if="moveMapRequests && mapRequestsMeta.totalRows > 0">
                        <vl-icon icon="move-left-right" class="vl-link__icon vl-link__icon--before" />Verplaats alle {{mapRequestsMeta.totalRows}} planaanvragen.
                    </vl-link>
                </template>
                <template v-slot:noresults>
                    <div v-vl-align:center>
                        <p>Uw zoekopdracht heeft geen resultaten opgeleverd.</p>
                        <vl-button @click="clearSearch" class="vl-button--link">Zoekvelden wissen</vl-button>
                    </div>
                </template>
            </ippro-datatable>
        </vl-column>
    </vl-form-grid>
</div>
