import { OSM } from 'ol/source'
import { defineComponent, inject, onMounted, Ref } from 'vue'

export default defineComponent({
  name: 'VlOlMapOsmSource',
  compatConfig: {
    MODE: 3
  },
  setup() {
    const source: Ref = inject('source')
    onMounted(() => {
      source.value = new OSM()
    })
  }
})
