<template>
    <component :is="tagName" :class="classes">
        <input :checked="active" class="vl-ol-map-base-layer-toggle__radio" name="base-layer-toggle" type="radio" v-bind="$attrs" @change="emitSelect" />
        <div class="vl-ol-map-base-layer-toggle__body">
            <p class="vl-ol-map-base-layer-toggle__body__label">
                <span>{{ title }}</span>
            </p>
            <img
                v-if="image"
                :alt="image.alt"
                :src="image.src"
                :srcset="image.srcset"
                class="vl-ol-map-base-layer-toggle__body__image"
            />
        </div>
    </component>
</template>

<script src="./vl-ol-map-base-layer-toggle.ts"></script>
