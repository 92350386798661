<template>
    <component :is="tagName" :class="classes">
        <div>
            <vl-loader />
            <div class="vl-ol-map-loader__title">
                <slot>
                    {{ title }}
                </slot>
            </div>
        </div>
    </component>
</template>

<script src="./vl-ol-map-loader.ts"></script>
